
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Environment, EnvironmentUser, User } from '@/models'

import { Conversation, Message } from '@/models/Conversation'
import moment from '@/plugins/moment'

import FuzzySearch from 'fuzzy-search'
import axios from 'axios'
import getEnv from '@/plugins/getEnv'

@Component({
  components: {},
})
export default class EnvironmentManageUsers extends Vue {
  @Prop({ type: Object, required: true }) environment!: Environment
  @Prop({ type: Object, required: true }) environmentUser!: EnvironmentUser
  @Prop({ type: String, default: '' }) userId!: string

  conversations: Conversation[] = []
  chats: Message[] = []
  estatus: string = ''

  searchQuery = ''
  dateQuery = ''
  noDataText = ''
  isChatOpen = false
  menu = false
  isLoading = true
  offChats = false

  get user(): User {
    return this.$store.state.auth.user
  }
  get isAdmin() {
    return (
      this.user.roles.indexOf('admin') >= 0 ||
      this.user.roles.indexOf('superAdmin') >= 0
    )
  }
  get conversartionFiltered() {
    let conversationsDateFiltered = this.conversations
    if (this.dateQuery) {
      conversationsDateFiltered =
        conversationsDateFiltered.filter(
          (e) => moment(e.createdAt).format('YYYY-MM') === this.dateQuery
        ) || []
    }
    if (this.estatus) {
      const estatus: { [index: string]: string } = {
        Cerrado: 'closed',
        Abierto: 'open',
      }
      conversationsDateFiltered =
        conversationsDateFiltered.filter(
          (e) => e.status === estatus[this.estatus]
        ) || []
    }
    const searcher = new FuzzySearch(conversationsDateFiltered, [
      'username',
      'userEmail',
      'subject',
      'channelName',
    ])
    return searcher.search(this.searchQuery || '')
  }
  get headers() {
    const headers = [
      { text: this.$t('Tickets.script.headers.status'), value: 'status' },
      {
        text: this.$t('Tickets.script.headers.channelName'),
        value: 'channelName',
      },
      { text: this.$t('Tickets.script.headers.subject'), value: 'subject' },
      { text: this.$t('Tickets.script.headers.createdAt'), value: 'createdAt' },
      {
        text: this.$t('Tickets.script.headers.lastMessageTimestamp'),
        value: 'lastMessageTimestamp',
      },
      {
        text: this.$t('Tickets.script.headers.userRating'),
        value: 'userRating',
      },
      { text: '', value: 'actions', sortable: false },
    ]
    if (this.environmentUser.canViewAllMessages) {
      headers.splice(2, 0, {
        text: this.$t('Tickets.script.headers.username'),
        value: 'username',
      })
    }
    return headers
  }
  get estatusSelection() {
    return [
      this.$t('Tickets.statuses.closed'),
      this.$t('Tickets.statuses.open'),
    ]
  }
  async mounted() {
    setTimeout(async () => {
      let conversations: Conversation[] = []
      try {
        conversations = await this.getConversations()
      } catch (e) {
        this.noDataText = this.$t('Tickets.script.setTimeOut.noData').toString()
      }
      conversations.forEach((e) => {
        e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm')
        e.lastMessageTimestamp = e.lastMessageTimestamp
          ? moment(e.lastMessageTimestamp).format('YYYY-MM-DD HH:mm')
          : ''
      })
      this.conversations = conversations
      this.isLoading = false
    }, 1000)
  }
  async getConversations() {
    const apiUrl = new URL(getEnv('VUE_APP_API_ENDPOINT'))
    let sessionId = btoa(
      JSON.stringify({
        d: apiUrl.host,
        s: this.$store.getters['auth/session'],
        t: Date.now(),
      })
    )
    const apiBack = axios.create({
      baseURL: 'https://tellus-v2-beta.services.simplex.biz/api',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Codeless ${this.environment._id} ${sessionId}`,
        TellusDate: Date.now(),
      },
    })
    try {
      const request = await apiBack.get('/conversations')
      return request.data.items
    } catch (e) {
      throw e
    }
  }
  async getChats(_id: string) {
    const apiUrl = new URL(getEnv('VUE_APP_API_ENDPOINT'))
    let sessionId = btoa(
      JSON.stringify({
        d: apiUrl.host,
        s: this.$store.getters['auth/session'],
        t: Date.now(),
      })
    )
    const apiBack = axios.create({
      baseURL: 'https://tellus-v2-beta.services.simplex.biz/api',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Codeless ${this.environment._id} ${sessionId}`,
        TellusDate: Date.now(),
      },
    })
    try {
      const request = await apiBack.get(`/conversations/${_id}/messages`)
      return request.data.items
    } catch (e) {
      throw e
    }
  }
  async handleChat(_id: string) {
    this.chats = []
    this.offChats = false
    this.isChatOpen = true
    let chatRequest
    try {
      chatRequest = await this.getChats(_id)
      if (!chatRequest[0]) return (this.offChats = true)
    } catch (e) {
      this.offChats = true
      return
    }
    chatRequest.forEach((e: Message) => {
      e.timestamp = moment(e.timestamp).format('YYYY-MM-DD HH:mm')
    })
    this.chats = chatRequest
  }
  saveDate(date: string) {
    ;(this.$refs.menu as Vue & { save: any }).save(date)
  }
}
