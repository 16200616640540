import { render, staticRenderFns } from "./Edit.vue?vue&type=template&id=2577732d"
import script from "./Edit.vue?vue&type=script&lang=ts"
export * from "./Edit.vue?vue&type=script&lang=ts"
import style0 from "./Edit.vue?vue&type=style&index=0&id=2577732d&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTabItem,VTabsItems})
