var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-print-none"},[_c('v-slide-y-transition',[(_vm.simulatedStatusBar)?_c('v-system-bar',{staticClass:"status-bar",staticStyle:{"position":"absolute","top":"0","z-index":"2","width":"100%"},attrs:{"color":_vm.simulatedStatusBackground,"dark":_vm.simulatedStatusDark,"status":""}},[_c('span',[_vm._v("12:30")]),_c('v-spacer'),_c('v-icon',[_vm._v("network_wifi")]),_c('v-icon',[_vm._v("signal_cellular_4_bar")]),_c('span',[_vm._v("100%")]),_c('v-icon',[_vm._v("battery_full")])],1):_vm._e()],1),_c('v-app-bar',{staticClass:"view-title",style:({
      ..._vm.backgroundStyle,
      ..._vm.shadowStyle,
      ..._vm.textColor,
      ..._vm.simulatedStatusStyle
    }),attrs:{"extended":_vm.extended,"dark":!!(_vm.view && _vm.view.titleBackground),"flat":"","color":_vm.color,"fixed":_vm.fixed,"app":_vm.fixed},scopedSlots:_vm._u([(_vm.extended)?{key:"extension",fn:function(){return [_c('v-toolbar-title',{staticStyle:{"margin-left":"0"},domProps:{"textContent":_vm._s(_vm.title || (_vm.view && _vm.view.title) || '')}}),_c('v-spacer'),(_vm.view && _vm.view.backButton)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.back}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("arrow_back")]),_vm._v(" "+_vm._s(_vm.$t('View_Navbar.backBtn'))+" ")],1):_vm._e(),(_vm.view && _vm.view.printButton)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.print}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("print")]),_vm._v(" "+_vm._s(_vm.$t('View_Navbar.printBtn'))+" ")],1):_vm._e()]},proxy:true}:null],null,true)},[_c('StatusBar',{attrs:{"statusStyle":_vm.statusStyle,"backgroundColor":_vm.statusBackground}}),(
        _vm.editing ||
        (_vm.sidebar && ((_vm.path && _vm.path !== '/') || _vm.$vuetify.breakpoint.mdAndDown))
      )?_c('v-app-bar-nav-icon',{style:(_vm.shadowStyle),attrs:{"disabled":_vm.editing},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('sidebarToggle')}}}):_vm._e(),(!_vm.extended)?_c('v-toolbar-title',{domProps:{"textContent":_vm._s(_vm.title || (_vm.view && _vm.view.title) || '')}}):_vm._e(),_c('v-spacer'),(_vm.editLinks)?_c('v-btn',{staticStyle:{"min-width":"16px","padding":"2px 8px","margin":"0"},style:(_vm.shadowStyle),attrs:{"href":`${_vm.adminLink}/design/${_vm.view._id}`,"target":"_blank","text":"","color":_vm.buttonColor,"title":_vm.$t('View_Navbar.editLinksBtn')}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e(),_c('NotificationsButton',{attrs:{"buttonColor":_vm.buttonColor,"shadowStyle":_vm.shadowStyle}}),(_vm.environment && _vm.environment.enableTellus)?_c('TellusButton',{attrs:{"buttonColor":_vm.buttonColor}}):_vm._e(),_c('UserMenu')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }