
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import md5 from 'md5'
import GlobalSearch from '@/components/GlobalSearch.vue'
import AdminNavbar from '@/components/AdminNavbar.vue'
import gql from 'graphql-tag'
import { Environment } from '@/models'
import { ApolloQueryResult } from 'apollo-client'
import DashboardAppBar from '@/components/DashboardAppBar.vue'

@Component({
  components: {
    GlobalSearch,
    AdminNavbar,
    DashboardAppBar,
  },
  apollo: {
    environment: {
      query: gql`
        query ($environmentId: ID) {
          environment(environmentId: $environmentId) {
            _id
            name
            url
            accentColor
            useCustomCss
            customCss
            logo {
              url
            }
            loginLogo {
              url
            }
          }
        }
      `,
      variables() {
        return {
          environmentId: this.environmentId,
        }
      },
      result(r: ApolloQueryResult<{ environment: Environment }>, key: string) {
        this.$store.commit('app/addRecent', r.data.environment)
      },
      skip() {
        return !this.environmentId
      },
    },
  },
})
export default class Admin extends Vue {
  globalSearchOpen = false
  environment: Environment | null = null

  @Prop({ type: String }) environmentId?: string

  @Watch('environment')
  async setEnvironmentVariables(env: Environment) {
    const style = document.getElementById('customCSS') as HTMLStyleElement
    if (!env) return

    if (env.accentColor) {
      this.$vuetify.theme.themes.light.primary =
        this.$vuetify.theme.themes.light.secondary =
        this.$vuetify.theme.themes.light.accent =
          env.accentColor || '#808080'

      this.$vuetify.theme.themes.dark.primary =
        this.$vuetify.theme.themes.dark.secondary =
        this.$vuetify.theme.themes.dark.accent =
          env.accentColor || '#808080'
    } else {
      this.$vuetify.theme.themes.dark.primary =
        this.$vuetify.theme.themes.light.primary = {
          base: '#1648FF',
          darken1: '#002DFF',
          darken2: '#002AE1',
          darken3: '#011CB2',
          darken4: '#01138C',
          lighten1: '#407BFF',
          lighten2: '#76AFFF',
          lighten3: '#A9D0FF',
          lighten4: '#CFE7FF',
          lighten5: '#E5F2FF',
        }
    }
    if (env.useCustomCss && env.customCss) {
      style.innerText = env.customCss
    } else {
      style.innerText = ''
    }
  }
}
