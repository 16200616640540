
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import {
  Environment,
  EnvironmentUser,
  PaginatedQueryResult,
  Notification as SodlabNotification
} from '@/models'
import openLink from '@/utils/route/openLink'
import urlBase64ToUint8Array from '@/utils/client/urlBase64ToUint8Array'
import gql from 'graphql-tag'
import getPushProvider from '@/utils/pushNotifications'
import { confirm } from '@/components/dialogs'
import moment from '@/plugins/moment'

@Component({
  apollo: {
    notifications: {
      query: gql`
        query paginatedNotifications(
          $environmentId: ID
          $environmentUserId: ID
        ) {
          notifications(
            limit: 100
            environmentId: $environmentId
            environmentUserId: $environmentUserId
          ) {
            items {
              _id
              path
              externalPath
              title
              content
              read
              iconUrl
              createdAt
            }
          }
        }
      `,
      variables() {
        return {
          environmentId: this.environment._id,
          environmentUserId: this.environmentUser._id
        }
      }
    },
    unseenNotificationCount: {
      query: gql`
        query unseenNotificationCount(
          $environmentId: ID
          $environmentUserId: ID
        ) {
          unseenNotificationCount: notificationCount(
            environmentId: $environmentId
            environmentUserId: $environmentUserId
          )
        }
      `,
      variables() {
        return {
          environmentId: this.environment._id,
          environmentUserId: this.environmentUser._id
        }
      }
    },
    $subscribe: {
      notificationInserted: {
        query: gql`
          subscription notificationInserted($environmentId: ID) {
            notificationInserted(environmentId: $environmentId)
          }
        `,
        variables() {
          return {
            environmentId: this.environment._id
          }
        },
        result({ data }: any) {
          // @ts-ignore
          return this.onNotificationInsert()
        }
      }
    }
  },
  filters: {
    fromNow: (date: string) =>
      moment(date)
        .fromNow(true)
        .split(' ')
        .map((w, i) => (i === 0 ? w : w.slice(0, 3)))
        .join(' ')
  }
})
export default class EnvironmentNotifications extends Vue {
  @Prop({ type: Boolean, required: true }) value!: boolean
  @Prop({ type: Object, required: true }) environment!: Environment
  @Prop({ type: Object, required: true }) environmentUser!: EnvironmentUser
  @Prop({ type: [Number, String] }) notificationCount?: number | string

  unseenNotificationCount = 0
  notifications: PaginatedQueryResult<SodlabNotification> | null = null
  pushNotifications = false
  floatingNotification: SodlabNotification | null = null
  floatingTimeout: any = null

  pushProvider = getPushProvider()
  pushNotificationsAvailable = false
  pushNotificationsLoading = true

  @Watch('notifications')
  updateNotificationCount() {
    const len = this.unseenNotificationCount
    this.$emit('update:notificationCount', len <= 99 ? len : '99+')
  }

  async onNotificationInsert() {
    await this.$apollo.queries.unseenNotificationCount.refetch()
    this.updateNotificationCount()
    const lastIds =
      (this.notifications && this.notifications.items.map((i) => i._id)) || []
    await this.$apollo.queries.notifications.refetch()
    const newNotification = this.notifications!.items.find(
      (n) => lastIds.indexOf(n._id) < 0
    )
    if (newNotification) {
      this.floatingNotification = newNotification
      if (this.floatingTimeout) {
        clearTimeout(this.floatingTimeout)
        this.floatingTimeout = null
      }
      this.floatingTimeout = setTimeout(() => {
        this.floatingNotification = null
        this.floatingTimeout = null
      }, 5000)
    }
  }

  get open() {
    return this.value
  }

  set open(v: boolean) {
    if (v) {
      this.markNotificationsSeen().catch((e) => console.error(e))
      this.unseenNotificationCount = 0
      this.updateNotificationCount()
    }
    this.$emit('input', v)
  }

  async mounted() {
    await this.checkPushState()
    if (
      !this.pushNotifications &&
      this.environment.autoEnableNotifications &&
      !localStorage.autoNotificationsPrompted
    ) {
      await this.promptAutoNotifications()
    }
  }

  async markNotificationsSeen() {
    if (this.unseenNotificationCount <= 0) return
    return this.$apollo.mutate({
      mutation: gql`
        mutation markNotificationsSeen(
          $environmentId: ID
          $environmentUserId: ID
        ) {
          markNotificationsSeen(
            environmentId: $environmentId
            environmentUserId: $environmentUserId
          )
        }
      `,
      variables: {
        environmentId: this.environment._id,
        environmentUserId: this.environmentUser._id
      }
    })
  }

  async promptAutoNotifications() {
    if (!this.pushNotificationsAvailable) return
    localStorage.autoNotificationsPrompted = 1
    if (window.mobileApp) {
      await this.togglePushNotifications()
    } else {
      await confirm(
        this.environment.notificationPrompt ||
        '¿Deseas recibir notificaciones push?',
        { cancelButtonText: 'No', okButtonText: 'Sí' }
      ).then(async () => {
        return this.togglePushNotifications()
      })
    }
  }

  async selectNotification(notification: SodlabNotification) {
    // await this.toggleRead(notification)
    if (notification.path)
      return openLink(notification.path, {}, notification.externalPath)
  }

  async clearNotifications() {
    this.notifications?.items.splice(0, this.notifications!.items.length)
    const { data } = await this.$apollo.mutate({
      mutation: gql`
        mutation clearNotifications(
          $environmentId: ID
          $environmentUserId: ID
        ) {
          clearNotifications(
            environmentId: $environmentId
            environmentUserId: $environmentUserId
          )
        }
      `,
      variables: {
        environmentId: this.environment._id,
        environmentUserId: this.environmentUser._id
      }
    })
    return data.clearNotifications
  }

  async toggleRead(notification: SodlabNotification) {
    this.notifications?.items.splice(
      this.notifications!.items.indexOf(notification),
      1
    )
    const { data } = await this.$apollo.mutate({
      mutation: gql`
        mutation toggleRead($environmentId: ID, $notificationId: ID) {
          toggleRead(
            environmentId: $environmentId
            notificationId: $notificationId
          )
        }
      `,
      variables: {
        environmentId: this.environment._id,
        notificationId: notification._id
      }
    })
    return data.toggleReaded
  }

  async checkPushState() {
    const { available, enabled } = await this.pushProvider.getState()
    this.pushNotificationsAvailable = available
    this.pushNotifications = enabled
    this.pushNotificationsLoading = false
  }

  async togglePushNotifications() {
    this.pushNotificationsLoading = true
    if (!this.pushNotificationsAvailable) return
    try {
      if (!this.pushNotifications) {
        // Subscribe to push
        const sub = await this.pushProvider.subscribe()
        await this.subscribePush({
          environmentId: this.environment._id,
          subscription: sub
        })
        await this.checkPushState()
        await this.$store.dispatch('snackbar/showSnackbar', {
          text: this.$t('Notifications.script.toggPNtfs.active')/* 'Se han activado las notificaciones Push' */,
          timeout: 3000
        })
      } else {
        // Unsubscribe from push
        const sub = await this.pushProvider.unsubscribe()
        if (sub) {
          await this.unsubscribePush({
            environmentId: this.environment._id,
            subscription: sub
          })
        }
        await this.checkPushState()
        await this.$store.dispatch('snackbar/showSnackbar', {
          text: this.$t('Notifications.script.toggPNtfs.disabled')/* 'Se han desactivado las notificaciones Push' */,
          timeout: 3000
        })
      }
    } catch (e) {
      console.error(e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: `Error: ${e.message}`,
        color: 'error',
        timeout: 5000
      })
    } finally {
      this.pushNotificationsLoading = false
    }
  }

  async subscribePush(variables: any) {
    const { data } = await this.$apollo.mutate({
      mutation: gql`
        mutation subscribePush($environmentId: ID, $subscription: JSON) {
          addPushSubscription(
            environmentId: $environmentId
            subscription: $subscription
          )
        }
      `,
      variables
    })
    return data.addPushSubscription
  }

  async unsubscribePush(variables: any) {
    const { data } = await this.$apollo.mutate({
      mutation: gql`
        mutation unsubscribePush($environmentId: ID, $subscription: JSON) {
          removePushSubscription(
            environmentId: $environmentId
            subscription: $subscription
          )
        }
      `,
      variables
    })
    return data.removePushSubscription
  }
}
