
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Environment } from '@/models'
import {
  EnvironmentCreateFragment,
  EnvironmentGeneralUpdateFragment
} from './fragments'
import Loading from '@/components/Loading.vue'
import PaginatedList from '@/components/PaginatedList.vue'
import ComponentCreateDialog from '@/components/ComponentCreateDialog.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import gql from 'graphql-tag'
import cleanData from '@/utils/gql/cleanData'
import { endpoints } from '@/components/componentTypes/endpoints'

@Component({
  components: {
    Loading,
    PaginatedList,
    ComponentCreateDialog
  }
})
export default class AdminSettingsEndpoints extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string

  componentType = endpoints

  saving = false

  createModalOpen = false

  /** Open or close the creation modal */
  toggleCreateModal(value: any) {
    this.createModalOpen =
      value != null && typeof value === 'boolean'
        ? value
        : !this.createModalOpen
  }

  handleCreate(component: any) {
    if (component && component._id) {
      return this.$router.push(
        `/${this.environmentId}/components/${this.componentType.namespace}/${component._id}`
      )
    }
  }
}
