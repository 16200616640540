
import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { Collection, PaginatedQueryResult } from '@/models'
import FuzzySearch from 'fuzzy-search'

import ComponentCreateDialog from '@/components/ComponentCreateDialog.vue'
import gql from 'graphql-tag'
import { CollectionCreateFragment } from '@/components/componentTypes/collections/fragments'
import Loading from '@/components/Loading.vue'

@Component({
  components: {
    Loading,
    ComponentCreateDialog,
  },
  apollo: {
    collections: {
      query: gql`
        query getCollections($environmentId: ID) {
          collections(environmentId: $environmentId) {
            items {
              ...CollectionCreate
            }
          }
        }
        ${CollectionCreateFragment}
      `,
      variables() {
        return {
          environmentId: this.environmentId,
        }
      },
    },
  },
})
export default class AdminCollections extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string

  drawer: boolean = true
  showSearchBox = false

  searchQuery = ''
  sortBy = 'name'
  createModalOpen = false

  get bindedStyles() {
    const designListCard: any = {
      backgroundColor: this.$vuetify.theme.currentTheme['background-2'],
      border: `1px solid ${this.$vuetify.theme.currentTheme['background-5']}`,
      height: '100%',
    }

    if (this.$vuetify.breakpoint.mdAndUp) designListCard.width = '100%'

    return {
      designListCard,
      subHeader: {
        borderBottom: `1px solid ${this.$vuetify.theme.currentTheme['background-5']}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1ch 1ch 1ch 2ch',
      },
      componentsDetails: {
        paddingLeft: this.$vuetify.breakpoint.mdAndUp ? '10px' : 0,
        height: 'calc(100vh - 120px)',
      },
    }
  }

  get showDrawer() {
    if (this.$vuetify.breakpoint.lgAndUp) {
      this.drawer = true
      return true
    } else {
      this.drawer = false
      return false
    }
  }

  collections: Partial<PaginatedQueryResult<Partial<Collection>>> = {
    items: [],
  }

  async handleCreate(collection?: Collection) {
    await this.$apollo.queries.collections.refetch()
    if (collection && collection._id) {
      return this.navigateToCollection(collection._id)
    }
  }

  navigateToCollection(collectionId: string) {
    return this.$router.push({
      name: 'adminCollectionEdit',
      params: {
        environmentId: this.environmentId,
        collectionId,
      },
    })
  }

  get filteredCollections() {
    const searcher = new FuzzySearch(this.collections.items || [], [
      'name',
      '_id',
    ])
    return searcher.search(this.searchQuery ?? '')
  }

  transformResult(col: Partial<Collection>) {
    return {
      ...col,
      title: col.name,
      subtitle: col._id,
      href: {
        name: 'adminCollectionEdit',
        params: {
          environmentId: this.environmentId,
          collectionId: col._id,
        },
      },
    }
  }

  toggleCreateModal(value: any) {
    this.createModalOpen =
      value != null && typeof value === 'boolean'
        ? value
        : !this.createModalOpen
  }
}
