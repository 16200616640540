
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import delay from '@/utils/async/delay'
import SignatureHelper from '@/components/fields/signature/SignatureHelper'

declare global {
  interface Window {
    Signature: any
  }
}

@Component({
  components: {
    Loading
  }
})
export default class SignatureCaptureModal extends Vue {
  @Prop({ type: Boolean, required: true }) open!: boolean
  @Prop({ type: Boolean }) captured?: boolean
  @Prop({ type: String }) signatureImage?: string
  @Prop({ type: String, default: ' ' }) who!: string
  @Prop({ type: String, default: 'Registro de Firma' }) reason!: string

  initializing = true
  sigHelper = new SignatureHelper(
    'AgAfADeB5ivVARNXYWNvbSBTaWduYXR1cmUgU0RLAAECgQMBAmQA'
  )

  cancelButtonText = 'Cancelar'
  pendingSignatureImage = ''
  pendingSignatureInk: string = ''
  statusIcon = ''
  statusText = ''

  get modalOpen() {
    return this.open
  }

  set modalOpen(v: boolean) {
    this.$emit('update:open', v)
  }

  @Watch('open')
  async initOrReset(v: boolean) {
    if (v) {
      this.statusIcon = 'signature'
      await this.initialize()
      if (!this.captured) await this.capture()
    } else {
      this.initializing = true
      this.pendingSignatureImage = ''
      this.pendingSignatureInk = ''
      this.statusIcon = ''
      this.statusText = ''
      this.cancelButtonText = 'Cancelar'
    }
  }

  async initialize() {
    try {
      this.statusText = 'Esperando dispositivo de firma...'
      this.statusIcon = 'settings_ethernet'
      this.sigHelper.on('helpText', (text) => (this.statusText = text))
      await this.sigHelper.init()
      this.initializing = false
    } catch (e) {
      this.initializing = false
      this.statusIcon = 'error'
      this.statusText = e.message || 'Ha ocurrido un error.'
      this.cancelButtonText = 'Cerrar'
    }
  }

  async capture() {
    this.pendingSignatureInk = ''
    this.pendingSignatureImage = ''
    try {
      this.statusText = 'Esperando firma...'
      const sigData = await this.sigHelper.captureHybrid(this.who, this.reason)
      this.pendingSignatureImage = sigData.image
      this.pendingSignatureInk = sigData.ink as string
    } catch (e) {
      this.initializing = false
      this.statusIcon = 'error'
      this.statusText = e.message || 'Ha ocurrido un error.'
      this.cancelButtonText = 'Cerrar'
    }
  }

  save() {
    this.$emit('save', {
      image: this.pendingSignatureImage,
      ink: this.pendingSignatureInk
    })
    this.modalOpen = false
  }

  cancel() {
    this.modalOpen = false
  }
}
