
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import { ApiEndpoint, ApiEndpointInputParameters } from '@/models'
import { ApiEndpointFragment, ApiEndpointUpdateFragment } from '../fragments'
import Fields from '@/components/form/Fields.vue'
import PreviewParams from '@/components/tools/PreviewParams.vue'
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'
import gql from 'graphql-tag'
import cleanData from '@/utils/gql/cleanData'
import { alert, confirmDelete, prompt } from '@/components/dialogs'
import ComponentSelect from '@/components/fields/componentSelect/Field.vue'
import CollectionFieldSelect from '@/components/fields/collectionFieldSelect/Field.vue'
import SingleSelect from '@/components/fields/select/Field.vue'
import ContentField from '@/components/fields/content/Field.vue'
import ComponentEditorDialog from '@/components/ComponentEditorDialog.vue'
import * as monaco from 'monaco-editor'
import MonacoEditor, { editorEnv } from '@/plugins/monaco'
import OptionFields from '@/components/form/OptionFields.vue'
import KeyValueList from '@/components/tools/KeyValueList.vue'
import { ApolloQueryResult } from 'node_modules/apollo-client'
import moment from '@/plugins/moment'

@Component({
  components: {
    Loading,
    Fields,
    PreviewParams,
    ComponentSelect,
    CollectionFieldSelect,
    SingleSelect,
    MonacoEditor,
    ComponentEditorDialog,
    ContentField,
    KeyValueList,
    OptionFields
  },
  apollo: {
    savedApiEndpoint: {
      query: gql`
        query getApiEndpoint($endpointId: ID) {
          savedApiEndpoint: apiEndpoint(endpointId: $endpointId) {
            ...ApiEndpoint
          }
        }
        ${ApiEndpointFragment}
      `,
      variables() {
        return {
          endpointId: this.componentId
        }
      }
    }
  }
})
export default class ApiEndpointEditorEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) componentId!: string
  activeTab = null

  saving = false
  running = false
  stopping = false
  previewParams: any[] = []
  previewError = ''

  savedApiEndpoint: Readonly<ApiEndpoint> | null = null
  apiEndpoint: Partial<ApiEndpoint> = {}

  inlineEditComponentType = ''
  inlineEditComponentId = ''
  inlineEditComponentOpen = false

  codeEditorOptions = {
    automaticLayout: true
  }

  nameErrors: string[] = []
  pathErrors: string[] = []

  availableMethods = ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'ALL']

  get dirty() {
    return !_isEqual(this.apiEndpoint, this.savedApiEndpoint)
  }

  @Watch('savedApiEndpoint')
  update(savedApiEndpoint: ApiEndpoint) {
    const newApiEndpoint = _cloneDeep(this.savedApiEndpoint)
    this.$set(this, 'apiEndpoint', newApiEndpoint)
  }

  @Watch('apiEndpoint.name')
  updateName(newName: string) {
    this.$emit('name', newName)
  }

  get methodColor() {
    return (
      {
        GET: 'cyan',
        HEAD: 'blue-grey',
        POST: 'green',
        PUT: 'purple',
        DELETE: 'red',
        PATCH: 'pink',
        ALL: 'primary'
      } as Record<string, string>
    )[this.apiEndpoint.method!]
  }

  async save() {
    if (!this.dirty) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($endpointId: ID, $endpoint: UpdateApiEndpointInput) {
            updateApiEndpoint(endpointId: $endpointId, endpoint: $endpoint) {
              ...ApiEndpoint
            }
          }
          ${ApiEndpointFragment}
        `,
        // Parameters
        variables: {
          endpointId: this.apiEndpoint._id,
          endpoint: cleanData(this.apiEndpoint, ApiEndpointUpdateFragment)
        }
      })

      this.savedApiEndpoint = result.data.updateApiEndpoint
      this.$emit('updated')
      this.$emit('save', result.data.updateApiEndpoint)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        this.$t('endpoint_fullEdit.script.deleteItem.confirm').toString()
        /*  '¿Seguro que quieres eliminar por completo este endpoint de la API?' */
      ))
    )
      return
    if (!this.apiEndpoint || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($endpointId: ID) {
            removeApiEndpoint(endpointId: $endpointId)
          }
        `,
        // Parameters
        variables: {
          endpointId: this.apiEndpoint._id
        }
      })
      this.$emit('updated')
      this.$emit('delete', result.data.deleteApiEndpoint)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  async rename() {
    const newName = await prompt(
      this.$t(
        'endpoint_fullEdit.script.rename.prompt'
      ).toString() /* 'Introduce un nuevo nombre para el endpoint' */,
      {
        defaultValue: this.apiEndpoint.name
      }
    )
    if (newName !== false) {
      this.apiEndpoint.name = newName as string
      await this.save()
    }
  }

  editorDidMount(editor: monaco.editor.IStandaloneCodeEditor) {
    editorEnv.environmentId = this.environmentId
    // Options
    const model = editor.getModel()
    model?.updateOptions({
      tabSize: 2,
      insertSpaces: true
    })

    // Actions
    editor.addAction({
      id: 'save',
      label: this.$t('endpoint_fullEdit.script.addAction.label').toString(),
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S],
      run: () => this.save()
    })
    // Events
    editorEnv.onEditComponent = (componentType, componentId) => {
      this.inlineEditComponentOpen = true
      this.inlineEditComponentType = componentType
      this.inlineEditComponentId = componentId
    }
  }
}
