
import { Component, Vue, Prop } from 'vue-property-decorator'
import { FileManagerFile } from '@/models'
import getEnv from '@/plugins/getEnv'

@Component({})
export default class DocumentFieldView extends Vue {
  @Prop({ type: Object, default: () => ({}) }) value!: FileManagerFile | string

  get url() {
    if (!this.value) return ''
    if (typeof this.value === 'string') return this.value
    const { bucket, key } = this.value
    const endpoint =
      getEnv('VUE_APP_AWS_S3_ENDPOINT') || 'https://s3.amazonaws.com'
    return `${endpoint}/${bucket}/${key}`
  }
}
