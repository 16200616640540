import { render, staticRenderFns } from "./selectUseCases.vue?vue&type=template&id=35130e91&scoped=true"
import script from "./selectUseCases.vue?vue&type=script&lang=ts"
export * from "./selectUseCases.vue?vue&type=script&lang=ts"
import style0 from "./selectUseCases.vue?vue&type=style&index=0&id=35130e91&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35130e91",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardActions,VIcon,VSpacer})
