var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CodeField',{attrs:{"errorMessages":_vm.errorMessages,"name":_vm.name,"schema":{
    ..._vm.schema,
    fieldOptions: {
      ..._vm.schema.fieldOptions,
      mode: 'application/json'
    }
  },"disabled":_vm.disabled,"environmentVariables":_vm.environmentVariables},model:{value:(_vm.syncValue),callback:function ($$v) {_vm.syncValue=$$v},expression:"syncValue"}})
}
var staticRenderFns = []

export { render, staticRenderFns }