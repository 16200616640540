var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"background-2"},[_c('v-card-text',{staticStyle:{"padding":"0px"}},[_c('MutationForm',{attrs:{"no-reset":"","mutation":"createCollection","submitButtonText":_vm.$t('collections_create.submitButtonText'),"submit-button-color":_vm.accentColor,"constants":{ environmentId: _vm.environmentId },"schema":{
        environmentId: {
          __graphQLType: 'ID'
        },
        _id: {
          label: _vm.$t('collections_create.schema._id.label'),
          __graphQLType: 'ID',
          type: 'string',
          description: _vm.$t('collections_create.schema._id.description'),
          fieldOptions: {
            color: _vm.accentColor,
            placeholder: _vm.$t('collections_create.schema._id.placeholder'),
            validatePattern: '^[a-z0-9]+$',
            validateCaseInsensitive: false,
            validateCustomError: _vm.$t('collections_create.schema._id.error')
          },

          newDesign: true
        },
        name: {
          label: _vm.$t('collections_create.schema.name.label'),
          type: 'string',
          description: _vm.$t('collections_create.schema.name.description'),
          fieldOptions: {
            color: _vm.accentColor,
            placeholder: _vm.$t('collections_create.schema.name.placeholder')
          },
          newDesign: true
        },
        type: {
          label: _vm.$t('collections_create.schema.type.label'),
          __graphQLType: 'String',
          type: 'select',
          fieldOptions: {
            color: _vm.accentColor,
            placeholder: _vm.$t('collections_create.schema.type.options[0]'),
            options: [
              {
                label: _vm.$t('collections_create.schema.type.options[0]'),
                value: 'collection'
              },
              {
                label: _vm.$t('collections_create.schema.type.options[1]'),
                value: 'vector'
              }
            ]
          },
          optional: true,
          newDesign: true
        }
      }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{staticStyle:{"border":"2px solid var(--v-primary-base)"},attrs:{"slot":"left-buttons","outlined":""},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v(_vm._s(_vm.$t('collections_create.cancelBtn')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }