
import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({
  components: {
  },
})
export default class TellusButton extends Vue {
  @Prop({ type: String, default: 'primary' }) buttonColor!: string
  activeColor = 'var(--tellus-active-color, #e0a23c)'
  get environment() {
    return this.$store.state.environment?.environment
  }
  get unreadMessageCountByClient() {
    return this.$store.state.app.unreadMessageCountByClient
  }
  get showTooltip() {
    return this.unreadMessageCountByClient > 0
  }
  get colorByState() {
    return this.unreadMessageCountByClient > 0 ? this.activeColor : this.buttonColor
  }
  openMessages() {
    this.$store.commit('app/updateOpenMessage', true)
  }
}
