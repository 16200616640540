var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-expansion-panels',{staticClass:"expand-links mb-2"},[_c('v-expansion-panel',{attrs:{"active-class":"active"}},[_c('v-expansion-panel-header',[_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"16px"}},[_c('div',{staticClass:"text-center link-handle align-self-center"},[_c('v-icon',[_vm._v("drag_handle")])],1),_c('span',[_vm._v("Link #"+_vm._s(_vm.index))])]),_c('div',{staticClass:"d-flex justify-end mr-3"},[_c('v-btn',{attrs:{"icon":"","height":"auto"},on:{"click":function($event){return _vm.$emit('delete')}}},[_c('v-icon',{attrs:{"color":"error","size":"16"}},[_vm._v("delete")])],1)],1)]),_c('v-expansion-panel-content',{staticClass:"background-3"},[(_vm.type === 'category')?_c('Fields',{attrs:{"schema":{
          title: {
            type: 'string',
            label: _vm.$t('links_field.schema.title.label'),
            description: _vm.$t('links_field.schema.title.description'),
            newDesign: true,
            fieldOptions: {
              placeholder: _vm.$t('links_field.schema.title.placeholder'),
            }
          },
          icon: {
            type: 'string',
            label: _vm.$t('links_field.schema.icon.label'),
            description: _vm.$t('links_field.schema.icon.description'),
            fieldOptions: {
              icon: _vm.normalizedIcon,
              placeholder: _vm.$t('links_field.schema.icon.placeholder'),
            },
            optional: true,
            newDesign: true
          }
        },"disabled":_vm.disabled,"environmentVariables":_vm.environmentVariables,"validationErrors":_vm.validationErrors},model:{value:(_vm.syncLink),callback:function ($$v) {_vm.syncLink=$$v},expression:"syncLink"}}):_vm._e(),_c('Fields',{attrs:{"id":"link-field-edit-card-2","schema":{
          path: {
            type: 'string',
            label: _vm.$t('links_field.schema.path.label'),
            description: _vm.$t('links_field.schema.path.description'),
            sizeSmall: 12,
            fieldOptions: {
              placeholder: _vm.$t('links_field.schema.path.placeholder'),
              validatePattern:
                '^\/(?:[a-z\d:%\s_0-9-]+(?:\/[a-z\d:%\s_0-9-]+)*)?$',
              validateCaseInsensitive: true,
              validateCustomError: _vm.$t('links_field.schema.path.error')
            },
            newDesign: true
          },
          roles: {
            type: 'roleSelect',
            __graphQLType: '[ID]',
            label: _vm.$t('links_field.schema.roles.label'),
            sizeSmall: 12,
            fieldOptions: {
              placeholder: _vm.$t('links_field.schema.roles.placeholder'),
              multi: true
            },
            optional: true,
            newDesign: true
          }
        },"disabled":_vm.disabled,"environmentVariables":_vm.environmentVariables,"validationErrors":_vm.validationErrors},model:{value:(_vm.syncLink),callback:function ($$v) {_vm.syncLink=$$v},expression:"syncLink"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }