
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FileManagerFile, FormField } from '@/models'
import { DocumentFieldOptions } from '.'
import getEnv from '@/plugins/getEnv'

@Component({
  components: {
    DocumentEditor: () => import('./Editor.vue')
  }
})
export default class DocumentEditorField extends Vue {
  /** Current Value */
  @Prop({ type: Object }) value?: FileManagerFile
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, required: true }) environmentVariables!: Record<
    string,
    any
  >
  /** Rules prop */
  @Prop({type: Array, required: false}) rules!:Record<string, any>[]
  /** Rules disabled */
  @Prop({type: Boolean, default: false}) norules!: boolean
  /** Icon disabled */
  @Prop({type: Boolean, default: true}) noicon!: boolean
  /** outlined prop */
  @Prop({type: Boolean, default: true}) outlined!: boolean
  /** dense prop */
  @Prop({type: Boolean, default: true}) dense!: boolean

  editorOpen = false

  openModal() {
    // if (this.value) return
    this.editorOpen = true
  }

  get getIcon(){
    if(this.noicon) return ''
    return 'attachment'
  }

  get url() {
    if (!this.value) return ''
    const { bucket, key } = this.value
    const endpoint =
      getEnv('VUE_APP_AWS_S3_ENDPOINT') || 'https://s3.amazonaws.com'
    return `${endpoint}/${bucket}/${key}`
  }

  /** Validation Rules */
  get validationRules() {

    if(this.norules) return []

    const rules = this.rules || []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || this.$t('document_field.script.validationRules.required'))
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): DocumentFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get displayValue() {
    if (!this.value) return
    return this.value.name
  }

  get fileIcon() {
    if (!this.value) return ''
    if (this.value.type.startsWith('image/')) return 'photo'
    if (this.value.type === 'application/pdf') return 'picture_as_pdf'
    return 'description'
  }

  handleFileSelect(file: FileManagerFile) {
    this.$emit('input', file)
  }

  clearValue() {
    this.$emit('input', null)
  }
}
