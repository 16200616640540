
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import _mapValues from 'lodash/mapValues'
import draggable from 'vuedraggable'
import { Environment, Field, FieldTypeDefinition } from '@/models'
import FieldTypes from '@/components/fields'
import profileFields from '@/components/form/Fields.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import cleanData from '@/utils/gql/cleanData'
import { EnvironmentProfileFieldsUpdateFragment } from './fragments'

const FieldIcons = _mapValues(FieldTypes, (f: any) => f.Icon) as Record<
  string,
  string
>

@Component({
  components: {
    Loading,
    profileFields,
    draggable,
  },
  apollo: {
    savedEnvironment: {
      query: gql`
        query getEnvironment($environmentId: ID) {
          savedEnvironment: environment(environmentId: $environmentId) {
            _id
            ...EnvironmentProfileFieldsUpdate
          }
        }
        ${EnvironmentProfileFieldsUpdateFragment}
      `,
      variables() {
        return {
          environmentId: this.environmentId,
        }
      },
    },
    fieldTypes: gql`
      query {
        fieldTypes {
          _id
          name
          optionsParams
        }
      }
    `,
  },
})
export default class EnvironmentEditprofileFields extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string

  savedEnvironment: Partial<Environment> | null = null
  environment: Partial<Environment> | null = null
  saving = false
  optionsSheetOpen: Record<string, any> = {}

  componentType: any = {
    titleIsMale: true,
    icon: 'edit',
  }

  fieldTypes: FieldTypeDefinition[] = []
  fieldErrors: Record<string, any> = {}

  @Watch('savedEnvironment')
  updateEnvironment() {
    this.environment = _cloneDeep(this.savedEnvironment)
    if (!this.environment) return
    if (!this.environment.profileFields) this.environment.profileFields = []
  }

  get hasFields() {
    //@ts-ignore
    return this.environment?.profileFields?.length > 0
  }

  get fieldNameValidationRules() {
    return [
      (fName: string) =>
        !!fName.trim() || this.$t('admin_profile.script.fieldName.required'),
      (fName: string) =>
        !!fName.match(/^[A-z][A-z\d]*$/) ||
        this.$t('admin_profile.script.fieldName.invalid'),
      (fName: string) =>
        (this.environment?.profileFields?.filter((f) => f.name === fName) || [])
          .length <= 1 || this.$t('admin_profile.script.fieldName.unique'),
    ]
  }

  get fieldTypesWithIcons() {
    return this.fieldTypes.map((f) => ({
      ...f,
      name: this.$t(f.name).toString(),
      icon: FieldIcons[f._id] || 'data_usage',
    }))
  }

  get valid() {
    if (!this.environment?.profileFields) return false
    return !this.environment.profileFields.some((f) => this.fieldErrors[f.name])
  }

  get dirty() {
    return !_isEqual(this.environment, this.savedEnvironment)
  }

  getTypeForField(field: Field) {
    if (!field || !field.type) return
    return this.fieldTypes.find((t) => t._id === field.type)
  }

  validateField(field: Field) {
    if (field.name.includes('user_')) {
      this.fieldErrors[field.name] = true // Indicar que hay un error
      return
    }

    const validationResult = this.fieldNameValidationRules.find(
      (validate) => validate(field.name) !== true
    )

    this.fieldErrors[field.name] = !!validationResult
  }

  handleFieldTypeChange(field: Field) {
    field.options = {}
  }

  addField() {
    if (!this.environment) return
    if (!this.environment.profileFields) this.environment.profileFields = []
    this.environment.profileFields.push({
      name: '',
      label: '',
      // @ts-ignore
      type: null,
      optional: true,
      options: {},
    })
  }

  removeField(field: Field) {
    if (!this.environment?.profileFields) return
    this.environment.profileFields = this.environment.profileFields.filter(
      (f) => f.name !== field.name
    )
  }

  openOptionsForField(field: Field) {
    this.$set(this, 'optionsSheetOpen', { [field.name]: true })
  }

  normalize() {
    if (!this.environment) return
    if (!this.environment.profileFields) this.environment.profileFields = []
    this.environment.profileFields = this.environment.profileFields.filter(
      (f) => f && f.name.trim() && f.type
    )
    this.environment?.profileFields?.forEach(this.validateField.bind(this))
  }

  async save() {
    if (!this.environment || this.saving) return
    this.normalize()
    if (!this.valid) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($environmentId: ID, $profileFields: [FieldInput]) {
            setEnvironmentProfileSchema(
              environmentId: $environmentId
              profileFields: $profileFields
            ) {
              _id
              ...EnvironmentProfileFieldsUpdate
            }
          }
          ${EnvironmentProfileFieldsUpdateFragment}
        `,
        // Parameters
        variables: {
          environmentId: this.environment._id,
          profileFields: cleanData(
            this.environment,
            EnvironmentProfileFieldsUpdateFragment
          ).profileFields,
        },
      })

      this.savedEnvironment = result.data.setEnvironmentProfileSchema
      this.$emit('save', result.data.updateFilter)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
