
import { Vue, Component, Prop, Inject, Provide } from 'vue-property-decorator'
import { ComponentType } from '@/models'
import * as ComponentTypes from '@/components/componentTypes'

@Component({
  name: 'CodelessComponent'
})
export default class CodelessComponent extends Vue {
  @Prop({ type: String, required: true }) type!: string
  @Prop({ type: String, required: true }) componentId!: string
  @Prop({ type: Object, default: () => ({}) }) params!: Record<string, any>
  @Prop({ type: Boolean, default: false }) noHeader!: boolean

  @Inject({ default: () => () => ({}) }) codelessRuntimeVars!: () => Record<
    string,
    any
  >

  @Provide() componentHeaderOptions() {
    return {
      noHeader: this.noHeader
    }
  }

  get environmentId() {
    return this.codelessRuntimeVars().environmentId
  }

  get viewParams() {
    return this.codelessRuntimeVars().viewParams
  }

  get componentProps() {
    return {
      environmentId: this.environmentId,
      componentId: this.componentId,
      itemDefinition: {
        id: this.componentId,
        type: this.type,
        sizeSmall: '12',
        sizeLarge: '12',
        blockId: this.componentId,
        namespace: this.type + 's'
      },
      preview: this.codelessRuntimeVars().preview,
      editing: this.codelessRuntimeVars().editing,
      viewParams: {
        ...this.viewParams,
        ...this.params
      }
    }
  }

  /** Current component type */
  get componentType(): ComponentType<any> {
    return (ComponentTypes as Record<string, ComponentType<any>>)[
      this.type + 's'
    ]
  }

  setParams(params: Record<string, any>) {
    this.$emit('setParams', params)
    const setParamsFn = this.codelessRuntimeVars().setParams
    if (setParamsFn) {
      setParamsFn(params)
    }
  }
}
