
import { Vue, Component, Prop } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { prompt } from '@/components/dialogs'
import getEnv from '@/plugins/getEnv'

@Component({})
export default class CollectionEditAdvancedVector extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) collectionId!: string

  working = false

  async clearData() {
    if (this.working) return
    const confirm = (await prompt(
      `ADVERTENCIA: Esto borrará todos los registros de ${this.collectionId}!\n` +
        'Esta operación no se puede deshacer.\n\n' +
        'Escribe "Eliminar Datos" abajo si estás seguro.',
      {
        okButtonText: 'Eliminar',
        okButtonIcon: 'delete',
        okButtonColor: 'error'
      }
    )) as string
    if (!confirm || confirm?.toLowerCase() !== 'eliminar datos') {
      return this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Se canceló el borrado de datos.'
      })
    }
    try {
      this.working = true
      await this.$apollo.mutate({
        mutation: gql`
          mutation removeDataCollection($collectionId: ID) {
            removeDataCollection(collectionId: $collectionId)
          }
        `,
        variables: {
          collectionId: this.collectionId
        }
      })
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Se vaciaron los registros de la colección.'
      })
    } catch (e) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: 'Ocurrió un error al intentar vaciar los registros.'
      })
    } finally {
      this.working = false
    }
  }

  async deleteCollection() {
    if (this.working) return
    const confirm = (await prompt(
      `ADVERTENCIA: Esto borrará por completo la colección ${this.collectionId}!\n` +
        'Esta operación no se puede deshacer. Adicionalmente, cualquier lógica que dependa de esta colección se romperá.\n\n' +
        `Escribe "${this.collectionId}" abajo si estás completamente seguro.`,
      {
        okButtonText: 'Eliminar',
        okButtonIcon: 'delete',
        okButtonColor: 'error'
      }
    )) as string
    if (!confirm || confirm?.toLowerCase() !== this.collectionId) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Se canceló el borrado de la colección.'
      })
    }
    try {
      this.working = true
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation removeCollection($collectionId: ID) {
            removeCollection(collectionId: $collectionId)
          }
        `,
        variables: {
          collectionId: this.collectionId
        }
      })
      this.$emit('delete', data.removeCollection)
    } catch (e) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: 'Ocurrió un error al intentar vaciar los registros.'
      })
    } finally {
      this.working = false
    }
  }

  get adminLink() {
    if (window.mobileApp) return ''
    if (
      getEnv('VUE_APP_ADMIN_DOMAIN_V1') &&
      this.environmentId &&
      this.collectionId
    ) {
      return `${document.location.protocol}//${getEnv(
        'VUE_APP_ADMIN_DOMAIN_V1'
      )}/${this.environmentId}/collections/${this.collectionId}`
    }
    return ''
  }
}
