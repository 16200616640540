var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:(_vm.bindedStyles.mainContainer),attrs:{"id":"mainContainer"}},[(_vm.$apollo.loading && !_vm.form._id)?_c('Loading'):[(_vm.currentTab !== 'fields')?_c('v-card-actions',{staticClass:"overflow-x-auto"},[_c('v-spacer'),_c('v-btn',{staticClass:"rounded-sm",staticStyle:{"min-width":"0"},attrs:{"color":"error","title":_vm.$t('forms_edit.deleteTitle'),"height":"36","width":"36","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete_outline")])],1),_c('v-btn',{staticClass:"rounded-sm px-4",style:({ border: `2px solid ${_vm.primaryColor}` }),attrs:{"outlined":"","title":_vm.$t('forms_edit.undoTitle'),"disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")]),_vm._v(" "+_vm._s(_vm.$t('forms_edit.undoBtn'))+" ")],1),_c('v-btn',{staticClass:"rounded-sm px-4",attrs:{"color":"primary","disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('forms_edit.saveBtn'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("save")])],1)],1):_vm._e(),(_vm.currentTab !== 'fields')?_c('v-divider'):_vm._e(),_c('v-tabs-items',{staticStyle:{"background-color":"transparent"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{style:(_vm.bindedStyles.content),attrs:{"value":"component"}},[_c('Fields',{attrs:{"id":"form-edit__tab-component-1","schema":{
            name: {
              type: 'string',
              label: _vm.$t('forms_edit.schema.name.label'),
              description: _vm.$t('forms_edit.schema.name.description'),
              newDesign: true,
            },
            title: {
              type: 'string',
              label: _vm.$t('forms_edit.schema.title.label'),
              description: _vm.$t('forms_edit.schema.title.description'),
              optional: true,
              newDesign: true,
            },
            roles: {
              type: 'roleSelect',
              __graphQLType: '[ID]',
              label: _vm.$t('forms_edit.schema.roles.label'),
              fieldOptions: {
                multi: true,
              },
              optional: true,
              newDesign: true,
            },
            collectionId: {
              type: 'collectionSelect',
              label: _vm.$t('forms_edit.schema.collectionId.label'),
              fieldOptions: {
                disabled: true,
                readonly: true,
              },
              newDesign: true,
            },
            type: {
              type: 'string',
              fieldType: 'select',
              label: _vm.$t('forms_edit.schema.type.label'),
              fieldOptions: {
                options: [
                  {
                    label: _vm.$t('forms_edit.schema.type.create'),
                    value: 'create',
                  },
                  {
                    label: _vm.$t('forms_edit.schema.type.update'),
                    value: 'update',
                  },
                  {
                    label: _vm.$t('forms_edit.schema.type.upsert'),
                    value: 'upsert',
                  },
                  {
                    label: _vm.$t('forms_edit.schema.type.void'),
                    value: 'void',
                  },
                ],
              },
              newDesign: true,
            },
          },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('v-slide-y-transition',[(_vm.form.type === 'update' || _vm.form.type === 'upsert')?_c('Fields',{attrs:{"id":"form-edit__tab-component-2","schema":{
              updateVariableName: {
                type: 'string',
                label: _vm.$t('forms_edit.schema.updateVariableName.label'),
                description: _vm.$t(
                  'forms_edit.schema.updateVariableName.description'
                ),
                optional: true,
                newDesign: true,
              },
              ...(_vm.form.type === 'update'
                ? {
                    hideEmpty: {
                      type: 'checkbox',
                      label: _vm.$t('forms_edit.schema.hideEmpty.label'),
                      optional: true,
                      newDesign: true,
                    },
                    emptyFormText: {
                      type: 'string',
                      label: _vm.$t('forms_edit.schema.emptyFormText.label'),
                      optional: true,
                      newDesign: true,
                    },
                  }
                : {}),
              updateFilterId: {
                type: 'string',
                fieldType: 'filterSelect',
                label: _vm.$t('forms_edit.schema.updateFilterId.label'),
                description: _vm.$t(
                  'forms_edit.schema.updateFilterId.description'
                ),
                optional: true,
                newDesign: true,
              },
              updateFilterParamNames: {
                type: 'string',
                label: _vm.$t('forms_edit.schema.updateFilterParamNames.label'),
                description: _vm.$t(
                  'forms_edit.schema.updateFilterParamNames.description'
                ),
                optional: true,
                newDesign: true,
              },
            },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{
              environmentId: _vm.environmentId,
              collectionId: _vm.form.collectionId,
            },"validationErrors":{}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}):_vm._e()],1)],1),_c('v-tab-item',{staticStyle:{"height":"100%"},attrs:{"value":"fields"}},[_c('FormFields',{attrs:{"name":"fields","errorMessages":"","disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{
            environmentId: _vm.environmentId,
            collectionId: _vm.form.collectionId,
            formId: _vm.form._id,
          },"validationErrors":{}},model:{value:(_vm.form.fields),callback:function ($$v) {_vm.$set(_vm.form, "fields", $$v)},expression:"form.fields"}},[_c('v-spacer'),_c('v-btn',{staticClass:"rounded-sm",staticStyle:{"min-width":"0"},attrs:{"color":"error","title":_vm.$t('forms_edit.deleteTitle'),"height":"36","width":"36","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete_outline")])],1),_c('v-btn',{staticClass:"rounded-sm px-4",style:({ border: `2px solid ${_vm.primaryColor}` }),attrs:{"outlined":"","title":_vm.$t('forms_edit.undoTitle'),"disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")]),_vm._v(" "+_vm._s(_vm.$t('forms_edit.undoBtn'))+" ")],1),_c('v-btn',{staticClass:"rounded-sm px-4",attrs:{"color":"primary","disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('forms_edit.saveBtn'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("save")])],1)],1)],1),_c('v-tab-item',{attrs:{"value":"appearance"}},[_c('div',{staticClass:"background pa-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('forms_edit.preview')))]),_c('div',{staticClass:"px-3 py-6",class:{
              'text-center': _vm.form.buttonAlignment === 'center',
              'text-right': _vm.form.buttonAlignment === 'right',
            }},[(_vm.form.reset)?_c('v-btn',{staticClass:"mr-2",attrs:{"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("undo")]),_vm._v(" "+_vm._s(_vm.form.resetButtonText || _vm.$t('forms_edit.undo'))+" ")],1):_vm._e(),(!_vm.form.hideSubmitButton)?_c('v-btn',{attrs:{"color":_vm.form.submitButtonBgColor || 'primary',"dark":!!_vm.form.submitButtonBgColor,"type":"submit"}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(_vm.normalizedIcon)}}),_vm._v(" "+_vm._s(_vm.normalizedButtonText)+" ")],1):_vm._e()],1)]),_c('div',{style:(_vm.bindedStyles.content)},[_c('Fields',{attrs:{"id":"form-edit__tab-appearance-1","schema":{
              buttonAlignment: {
                type: 'select',
                label: _vm.$t('forms_edit.schema.buttonAlignment.label'),
                fieldOptions: {
                  options: [
                    {
                      label: _vm.$t('forms_edit.schema.buttonAlignment.left'),
                      value: 'left',
                    },
                    {
                      label: _vm.$t('forms_edit.schema.buttonAlignment.center'),
                      value: 'center',
                    },
                    {
                      label: _vm.$t('forms_edit.schema.buttonAlignment.right'),
                      value: 'right',
                    },
                  ],
                },
                optional: true,
                newDesign: true,
              },
              hideSubmitButton: {
                type: 'checkbox',
                label: _vm.$t('forms_edit.schema.hideSubmitButton.label'),
                options: {
                  disabled: _vm.form.type !== 'void',
                },
                optional: true,
                newDesign: true,
              },
              ...(!_vm.form.hideSubmitButton
                ? {
                    submitButtonBgColor: {
                      type: 'color',
                      label: _vm.$t(
                        'forms_edit.schema.submitButtonBgColor.label'
                      ),
                      optional: true,
                      newDesign: true,
                    },
                    submitButtonText: {
                      type: 'string',
                      label: _vm.$t('forms_edit.schema.submitButtonText.label'),
                      optional: true,
                      fieldOptions: {
                        placeholder: _vm.normalizedButtonText,
                      },
                      sizeSmall: 12,
                      sizeLarge: 6,
                      newDesign: true,
                    },
                    submitButtonIcon: {
                      type: 'string',
                      label: _vm.$t('forms_edit.schema.submitButtonIcon.label'),
                      description: _vm.$t(
                        'forms_edit.schema.submitButtonIcon.description'
                      ),
                      sizeSmall: 12,
                      sizeLarge: 6,
                      optional: true,
                      fieldOptions: {
                        placeholder: _vm.normalizedIcon,
                      },
                      newDesign: true,
                    },
                  }
                : {}),
              reset: {
                type: 'checkbox',
                label: _vm.$t('forms_edit.schema.reset.label'),
                optional: true,
                newDesign: true,
              },
            },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{
              environmentId: _vm.environmentId,
              collectionId: _vm.form.collectionId,
            },"validationErrors":{}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('v-slide-y-transition',[(_vm.form.reset)?_c('v-card-text',{staticClass:"mt-n6"},[_c('label',[_vm._v(_vm._s(_vm.$t('forms_edit.reset')))]),_c('v-text-field',{staticClass:"mt-1",attrs:{"dense":"","outlined":"","filled":""},model:{value:(_vm.form.resetButtonText),callback:function ($$v) {_vm.$set(_vm.form, "resetButtonText", $$v)},expression:"form.resetButtonText"}})],1):_vm._e()],1)],1)]),_c('v-tab-item',{style:(_vm.bindedStyles.content),attrs:{"value":"actions"}},[_c('Fields',{attrs:{"schema":{
            _authSection: {
              type: 'section',
              editableLabel: _vm.$t('forms_edit.schema.authSection.label'),
            },
            requireTwoFactor: {
              type: 'checkbox',
              label: _vm.$t('forms_edit.schema.requireTwoFactor.label'),
              optional: true,
              newDesign: true,
            },
            ...(_vm.form.type !== 'void'
              ? {
                  _validationsSection: {
                    type: 'section',
                    editableLabel: _vm.$t(
                      'forms_edit.schema.validationsSection.label'
                    ),
                  },
                  validationsIds: {
                    type: 'validationSelect',
                    label: _vm.$t('forms_edit.schema.validationsIds.label'),
                    fieldOptions: {
                      multi: true,
                    },
                    optional: true,
                    newDesign: true,
                  },
                  validationsMode: {
                    type: 'select',
                    label: _vm.$t('forms_edit.schema.validationsMode.label'),
                    fieldOptions: {
                      options: [
                        {
                          value: 'AND',
                          label: _vm.$t('forms_edit.schema.validationsMode.and'),
                        },
                        {
                          value: 'OR',
                          label: _vm.$t('forms_edit.schema.validationsMode.or'),
                        },
                      ],
                    },
                    optional: true,
                    newDesign: true,
                  },
                }
              : {}),
            _logicSection: {
              type: 'section',
              editableLabel: _vm.$t('forms_edit.schema.logicSection.label'),
            },
            afterHooksIds: {
              type: 'hookSelect',
              label:
                _vm.form.type !== 'void'
                  ? _vm.$t('forms_edit.schema.afterHooksIds.labelOpt1')
                  : _vm.$t('forms_edit.schema.afterHooksIds.labelOpt2'),
              fieldOptions: {
                multi: true,
                allowedTypes: _vm.form.type === 'void' ? ['script'] : undefined,
              },
            },
            shouldStopHooksOnError: {
              type: 'checkbox',
              label: _vm.$t('forms_edit.schema.shouldStopHooks.label'),
              optional: true,
              newDesign: true,
            },
            ...(_vm.form.type !== 'void'
              ? {
                  shouldDeleteItemOnError: {
                    type: 'checkbox',
                    label: _vm.$t('forms_edit.schema.shouldDeleteItem.label'),
                    optional: true,
                    newDesign: true,
                  },
                }
              : {}),
            _onFinishSection: {
              type: 'section',
              editableLabel: _vm.$t('forms_edit.schema.onFinishSection.label'),
            },
            onSuccessViewPath: {
              type: 'string',
              label: _vm.$t('forms_edit.schema.onSuccessViewPath.label'),
              optional: true,
              newDesign: true,
            },
            onSuccessNewTab: {
              type: 'checkbox',
              label: _vm.$t('forms_edit.schema.onSuccessNewTab.label'),
              optional: true,
              newDesign: true,
            },
            onSuccessEnvironmentVariables: {
              type: 'collectionFieldSelect',
              label: _vm.$t('forms_edit.schema.onSuccessEnvironment.label'),
              fieldOptions: {
                multi: true,
              },
              optional: true,
              newDesign: true,
            },
            ...(_vm.form.type === 'void'
              ? {
                  dynamicViewParams: {
                    type: 'checkbox',
                    label: _vm.$t('forms_edit.schema.dynamicViewParams.label'),
                    optional: true,
                    newDesign: true,
                  },
                }
              : {}),
            postItemToUrl: {
              type: 'string',
              label: _vm.$t('forms_edit.schema.postItemToUrl.label'),
              description: _vm.$t('forms_edit.schema.postItemToUrl.description'),
              optional: true,
              newDesign: true,
            },
          },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{
            environmentId: _vm.environmentId,
            collectionId: _vm.form.collectionId,
          },"validationErrors":{}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }