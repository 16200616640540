
import { Vue, Component, Prop } from 'vue-property-decorator'
import Navbar from './Navbar.vue'
import { Environment, EnvironmentUser, User, UserProfile } from '@/models'
import _cloneDeep from 'lodash/cloneDeep'
import _pick from 'lodash/pick'
import _mapKeys from 'lodash/mapKeys'
import Form from '@/components/form/Form.vue'
import Loading from '@/components/Loading.vue'
import EnvironmentSidebar from '../Sidebar.vue'
import gql from 'graphql-tag'
import { ApolloError } from 'apollo-client'
import getEnv from '@/plugins/getEnv'

@Component({
  components: {
    Form,
    Navbar,
    Loading,
    EnvironmentSidebar,
  },
})
export default class EnvironmentUserProfile extends Vue {
  @Prop({ type: Object, required: true }) environment!: Environment
  @Prop({ type: Object, required: true }) environmentUser!: EnvironmentUser
  @Prop({ type: Boolean, default: false }) sidebar!: boolean
  @Prop({ type: Boolean, default: false }) sidebarOpen!: boolean
  @Prop({ type: [Number, String] }) notificationCount?: number | string
  @Prop({ type: [Number, String] }) unreadMessageCount?: number | string

  desktopApp = getEnv('VUE_APP_IS_DESKTOP')

  tab = 'profile'
  profile: UserProfile | undefined | null = null
  profileSchema = {
    avatar: {
      label: this.$t('Profile_index.schema.profileSchema.avatar.label'),
      type: 'file',
      optional: true,
      fieldOptions: {
        fileType: 'image',
        placeholder: this.$t(
          'Profile_index.schema.profileSchema.avatar.placeholder'
        ),
      },
    },
    firstName: {
      label: this.$t('Profile_index.schema.profileSchema.firstName.label'),
      type: 'string',
      optional: true,
    },
    lastName: {
      label: this.$t('Profile_index.schema.profileSchema.lastName.label'),
      type: 'string',
      optional: true,
    },
  }
  profileValidationErrors: Record<string, string | boolean> = {}
  updatingProfile = false

  passwordValidationErrors: Record<string, string | boolean> = {}
  updatingPassword = false

  generating2fa = false
  twoFactorSecret = ''
  twoFactorQR = ''
  twoFactorConfirmationCode = ''
  
  isSidebarOpen =!this.$vuetify.breakpoint.mdAndDown

  mounted() {
    this.profile = _cloneDeep(this.user.profile)
  }

  get hasSidebar() {
    return !this.environment.allowLightweightLayout
  }
  
  get user(): User {
    return this.$store.state.auth.user
  }

  get envProfile() {
    return this.environmentUser.profile || {}
  }

  get userAvatar(): string {
    return this.$store.getters['auth/userAvatarURL']
  }

  get twoFactorQRb64() {
    if (!this.twoFactorQR) return ''
    return 'data:image/svg+xml;base64,' + btoa(this.twoFactorQR)
  }

  async updateProfile(profileData: UserProfile) {
    if (this.updatingProfile) return
    this.updatingProfile = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation updateProfile($userId: ID, $profile: UserProfileInput) {
            user: setUserProfile(userId: $userId, profile: $profile) {
              profile {
                name
                avatar {
                  _id
                  name
                  type
                  key
                  bucket
                  url
                }
                firstName
                lastName
              }
            }
          }
        `,
        variables: {
          userId: this.user._id,
          profile: _pick(profileData, Object.keys(this.profileSchema)),
        },
      })
      const newProfile = data.user.profile
      this.profile = _cloneDeep(newProfile)
      this.$store.commit('auth/setUserData', {
        ...this.user,
        profile: newProfile,
      })
      await this.onSuccess('Perfil actualizado exitosamente!')
    } catch (e) {
      await this.onError(e, 'profileValidationErrors')
    } finally {
      this.updatingProfile = false
    }
  }

  async updatePassword(data: {
    oldPassword: string
    newPassword: string
    confirm: string
  }) {
    if (this.updatingPassword) return
    // Check confirmation
    if (data.newPassword !== data.confirm) {
      this.$set(this, 'passwordValidationErrors', {
        confirm: this.$t('Profile_index.schema.updatePassword.incorrectPass').toString(),
      })
      return
    }
    this.updatingPassword = true
    this.$set(this, 'passwordValidationErrors', {})
    try {
      const { data: result } = await this.$apollo.mutate({
        mutation: gql`
          mutation changePassword($oldPassword: String, $newPassword: String) {
            result: changePassword(
              oldPassword: $oldPassword
              newPassword: $newPassword
            )
          }
        `,
        variables: {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        },
      })
      if (result) {
        await this.onSuccess(this.$t('Profile_index.schema.updatePassword.success').toString())
      } else {
        throw new Error(this.$t('Profile_index.schema.updatePassword.error').toString())
      }
    } catch (e) {
      await this.onError(e, 'passwordValidationErrors')
    } finally {
      this.updatingPassword = false
    }
  }

  async generate2faSecret() {
    if (this.generating2fa) return
    this.generating2fa = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation generateTwoFactorSecret {
            result: generateTwoFactorSecret {
              base32
              qrCode
            }
          }
        `,
      })
      const { base32, qrCode } = data.result
      this.twoFactorQR = qrCode
      this.twoFactorSecret = base32
    } catch (e) {
      console.error(e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error',
        timeout: 10000,
      })
    } finally {
      this.generating2fa = false
    }
  }

  async enable2fa() {
    if (this.generating2fa) return
    this.generating2fa = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation activateTwoFactor($code: String) {
            result: activateTwoFactor(code: $code) {
              _id
              hasTwoFactor
            }
          }
        `,
        variables: {
          code: this.twoFactorConfirmationCode,
        },
      })
      const { hasTwoFactor } = data.result
      this.twoFactorQR = ''
      this.twoFactorSecret = ''
      this.twoFactorConfirmationCode = ''
      this.$store.commit('auth/setUserData', { ...this.user, hasTwoFactor })
      await this.onSuccess(this.$t('Profile_index.schema.enable2fa.success').toString())
    } catch (e) {
      console.error(e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error',
        timeout: 10000,
      })
    } finally {
      this.generating2fa = false
    }
  }

  async disable2fa() {
    if (this.generating2fa) return
    this.generating2fa = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation disableTwoFactor {
            result: disableTwoFactor {
              _id
              hasTwoFactor
            }
          }
        `,
      })
      const { hasTwoFactor } = data.result
      this.$store.commit('auth/setUserData', { ...this.user, hasTwoFactor })
      await this.onSuccess(this.$t('Profile_index.schema.disable2fa.success').toString())
    } catch (e) {
      console.error(e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error',
        timeout: 10000,
      })
    } finally {
      this.generating2fa = false
    }
  }

  onSuccess(text: string) {
    return this.$store.dispatch('snackbar/showSnackbar', { text })
  }

  async onError(e: ApolloError, validationErrorsKey: string) {
    console.error(e)
    let message = e.message
    if (e.graphQLErrors) {
      e.graphQLErrors.forEach((err: any) => {
        if (err.error === 'validationError') {
          // this.$set(this, validationErrorsKey, err.validationErrors)
          this.$set(
            this,
            validationErrorsKey,
            _mapKeys(err.validationErrors, (v, k) => k.replace(/^data\./, ''))
          )
          return
        }
      })
      message = e.graphQLErrors.map((e) => e.message).join(', ')
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + message,
        color: 'error',
        timeout: 10000,
      })
    }
  }
}
