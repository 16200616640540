
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

export default {
  i18n,
};
