var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-card-text',{staticStyle:{"padding":"0px"}},[_c('MutationForm',{attrs:{"no-reset":"","mutation":"createView","constants":{ environmentId: _vm.environmentId },"submitButtonText":_vm.$t('view_create.submitButtonText'),"submitButtonIcon":"add","submit-button-color":_vm.accentColor,"schema":{
        environmentId: {
          __graphQLType: 'ID'
        },
        path: {
          label: _vm.$t('view_create.schema.path.label'),
          type: 'string',
          description: _vm.$t('view_create.schema.path.description'),
          fieldOptions: {
            color: _vm.accentColor,
            placeholder: _vm.$t('view_create.schema.path.placeholder'),
            validatePattern:
              '^\/(?:[a-z\d:%\s_0-9-]+(?:\/[a-z\d:%\s_0-9-]+)*)?$',
            validateCaseInsensitive: true,
            validateCustomError: _vm.$t('view_create.schema.path.validateError')
          },
          newDesign: true
        },
        name: {
          label: _vm.$t('view_create.schema.name.label'),
          type: 'string',
          description: _vm.$t('view_create.schema.name.description'),
          fieldOptions: {
            color: _vm.accentColor,
            placeholder: _vm.$t('view_create.schema.name.placeholder')
          },
          newDesign: true
        },
        title: {
          label: _vm.$t('view_create.schema.title.label'),
          type: 'string',
          description: _vm.$t('view_create.schema.title.description'),
          fieldOptions: {
            color: _vm.accentColor,
            placeholder: _vm.$t('view_create.schema.title.placeholder')
          },
          newDesign: true
        }
      }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{staticStyle:{"border":"2px solid var(--v-primary-base)"},attrs:{"slot":"left-buttons","outlined":""},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v(" "+_vm._s(_vm.$t('view_create.cancelBtn'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }