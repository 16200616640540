
import { Component, Vue, Prop } from 'vue-property-decorator'
import CodelessLoading from '@/components/CodelessLoading.vue'

@Component({
  components: {
    CodelessLoading
  }
})
export default class TestView extends Vue {}
