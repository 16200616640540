var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:(_vm.bindedStyles.mainContainer),attrs:{"id":"mainContainer"}},[(_vm.$apollo.loading && !_vm.savedIndicator)?_c('Loading'):[_c('v-card-actions',{staticClass:"overflow-x-auto"},[_c('v-spacer'),_c('v-btn',{staticClass:"rounded-sm",staticStyle:{"min-width":"0"},attrs:{"color":"error","height":"36","width":"36","title":_vm.$t('indicators_edit.deleteTitle'),"disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.deleteItem}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete_outline")])],1),_c('v-btn',{staticClass:"rounded-sm px-4",style:({ border: `2px solid ${_vm.primaryColor}` }),attrs:{"outlined":"","title":_vm.$t('indicators_edit.undoTitle'),"disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.updateSavedIndicator}},[_c('v-icon',[_vm._v("undo")]),_vm._v(" "+_vm._s(_vm.$t('indicators_edit.undoBtn'))+" ")],1),_c('v-btn',{staticClass:"rounded-sm px-4",attrs:{"color":"primary","disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('indicators_edit.saveBtn'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("save")])],1)],1),_c('v-divider'),_c('v-card-text',{style:(_vm.bindedStyles.content)},[_c('Fields',{attrs:{"schema":{
          name: {
            type: 'string',
            label: _vm.$t('indicators_edit.schema.name.label'),
            description: _vm.$t('indicators_edit.schema.name.description'),
            sizeSmall: 12,
            newDesign: true
          },
          title: {
            type: 'string',
            label: _vm.$t('indicators_edit.schema.title.label'),
            description: _vm.$t('indicators_edit.schema.title.description'),
            sizeSmall: 12,
            optional: true,
            newDesign: true
          },
          roles: {
            type: 'roleSelect',
            __graphQLType: '[ID]',
            label: _vm.$t('indicators_edit.schema.roles.label'),
            fieldOptions: {
              multi: true
            },
            optional: true,
            newDesign: true
          },
          indicatorTypeId: {
            type: 'select',
            __graphQLType: 'ID',
            label: _vm.$t('indicators_edit.schema.indicatorTypeId.label'),
            fieldOptions: {
              options: _vm.availableIndicatorTypes
            },
            newDesign: true
          },
          link: {
            type: 'string',
            label: _vm.$t('indicators_edit.schema.link.label'),
            description: _vm.$t('indicators_edit.schema.link.description'),
            fieldOptions: {
              icon: 'attachment'
            },
            optional: true,
            newDesign: true
          },
          disableRefresh: {
            type: 'boolean',
            fieldType: 'checkbox',
            label: _vm.$t('indicators_edit.schema.disableRefresh.label'),
            optional: true,
            newDesign: true
          }
        },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.indicator),callback:function ($$v) {_vm.indicator=$$v},expression:"indicator"}}),(_vm.indicatorType)?[_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-slide-y-transition',[_c('v-row',{attrs:{"no-gutters":true}},[(_vm.indicatorType.requireCollection)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[(_vm.indicatorType.requireCollection)?_c('ComponentSelect',{key:"collectionId",attrs:{"name":"collectionId","schema":{
                    label: _vm.$t('indicators_edit.schema.collectionId'),
                    fieldOptions: {
                      componentTypeName: 'collections'
                    },
                    newDesign: true
                  },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId }},model:{value:(_vm.indicator.collectionId),callback:function ($$v) {_vm.$set(_vm.indicator, "collectionId", $$v)},expression:"indicator.collectionId"}}):_vm._e()],1):_vm._e(),(_vm.indicatorType.requireField && _vm.indicator.collectionId)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[(_vm.indicatorType.requireField && _vm.indicator.collectionId)?_c('CollectionFieldSelect',{key:"fieldName",attrs:{"name":"fieldName","schema":{
                    label: _vm.$t('indicators_edit.schema.fieldName'),
                    fieldOptions: {
                      collectionId: _vm.indicator.collectionId
                    },
                    newDesign: true
                  },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId }},model:{value:(_vm.indicator.fieldName),callback:function ($$v) {_vm.$set(_vm.indicator, "fieldName", $$v)},expression:"indicator.fieldName"}}):_vm._e()],1):_vm._e(),(_vm.indicator.collectionId)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[(_vm.indicator.collectionId)?_c('ComponentSelect',{key:"filtersIds",attrs:{"name":"filtersIds","schema":{
                    label: _vm.$t('indicators_edit.schema.filtersIds'),
                    fieldOptions: {
                      multi: true,
                      componentTypeName: 'filters',
                      filterByKey: 'collectionId',
                      filterByValue: _vm.indicator.collectionId
                    },
                    newDesign: true
                  },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId }},model:{value:(_vm.indicator.filtersIds),callback:function ($$v) {_vm.$set(_vm.indicator, "filtersIds", $$v)},expression:"indicator.filtersIds"}}):_vm._e()],1):_vm._e(),(
                  _vm.indicator.collectionId &&
                  _vm.indicator.filtersIds &&
                  _vm.indicator.filtersIds.length > 1
                )?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[(
                    _vm.indicator.collectionId &&
                    _vm.indicator.filtersIds &&
                    _vm.indicator.filtersIds.length > 1
                  )?_c('ComponentSelect',{key:"filterByDefault",attrs:{"name":"filterByDefault","schema":{
                    label: _vm.$t('indicators_edit.schema.filterByDefault'),
                    fieldOptions: {
                      componentTypeName: 'filters',
                      filterByKey: '_id',
                      filterByValue: _vm.indicator.filtersIds
                    },
                    newDesign: true
                  },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId }},model:{value:(_vm.indicator.filterByDefault),callback:function ($$v) {_vm.$set(_vm.indicator, "filterByDefault", $$v)},expression:"indicator.filterByDefault"}}):_vm._e()],1):_vm._e(),(_vm.indicator.filtersIds && _vm.indicator.filtersIds.length)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[(_vm.indicator.filtersIds && _vm.indicator.filtersIds.length)?_c('v-checkbox',{key:"allowsNoFilter",staticClass:"pb-0 mb-0",attrs:{"label":"Se puede usar sin filtro"},model:{value:(_vm.indicator.allowsNoFilter),callback:function ($$v) {_vm.$set(_vm.indicator, "allowsNoFilter", $$v)},expression:"indicator.allowsNoFilter"}}):_vm._e()],1):_vm._e(),(_vm.indicator.filtersIds && _vm.indicator.filtersIds.length > 1)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[(
                    _vm.indicator.filtersIds && _vm.indicator.filtersIds.length > 1
                  )?_c('v-checkbox',{key:"orderFiltersByName",staticClass:"pt-0 mt-0",attrs:{"label":"Ordenar filtros alfabéticamente"},model:{value:(_vm.indicator.orderFiltersByName),callback:function ($$v) {_vm.$set(_vm.indicator, "orderFiltersByName", $$v)},expression:"indicator.orderFiltersByName"}}):_vm._e()],1):_vm._e()],1)],1),(_vm.indicator.options)?_c('h1',{staticClass:"headline mt-2 font-weight-bold"},[_vm._v(" Opciones del indicador ")]):_vm._e(),_c('OptionFields',{attrs:{"schema":_vm.indicatorType.optionsParams,"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{
              environmentId: _vm.environmentId,
              collectionId: _vm.indicator.collectionId
            },"validationErrors":{}},model:{value:(_vm.indicatorOptions),callback:function ($$v) {_vm.indicatorOptions=$$v},expression:"indicatorOptions"}})],1)]:_vm._e()],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }