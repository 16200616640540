
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { View, ComponentType, Environment } from '@/models'
import gql from 'graphql-tag'
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'
import Field from '@/components/fields/Field.vue'
import Navbar from '@/views/Environment/View/Navbar.vue'
import Loading from '@/components/Loading.vue'
import CodelessLoading from '@/components/CodelessLoading.vue'
import SelectFunctions from './assistantSteps/selectFunctions.vue'
import { UseCaseView, UseCase, SubUseCase } from '@/models/AiAssistantContext'
import { alert } from '@/components/dialogs'

@Component({
  components: {
    Navbar,
    Field,
    Loading,
    CodelessLoading,
    SelectFunctions
  },
  apollo: {
    environment: {
      query: gql`
        query getEnvironment($environmentId: ID) {
          environment(environmentId: $environmentId) {
            _id
            aiAssistantContext
          }
        }
      `,
      variables() {
        return {
          environmentId: this.environmentId
        }
      }
    }
  }
})
export default class AdminDesignAssistant extends Vue {
  @Prop() environmentId!: string
  @Prop() viewId!: string

  environment: Partial<Environment> | null = null

  useCase: UseCase | null = null
  view: UseCaseView | null = null
  viewIndex = 0
  subCases: SubUseCase[] = []

  generatingLayout = false
  layoutComponent: any = null

  previewSize = 'desktop'
  previewTheme = null

  get ctx() {
    return this.environment?.aiAssistantContext || {}
  }

  componentToAdd: ComponentType<any> | null = null

  @Watch('environment', { immediate: true })
  async updateContextView() {
    if (!this.environment?.aiAssistantContext) return
    for (const useCase of this.environment.aiAssistantContext.useCases) {
      for (const view of useCase.views) {
        if (view.viewId === this.viewId) {
          this.useCase = useCase
          this.view = view
          this.viewIndex = useCase.views.indexOf(view)
          this.subCases = useCase.subUseCases.filter(
            (subCase) => subCase.viewIndex === this.viewIndex
          )
          return
        }
      }
    }
    await alert('Se produjo un error al cargar el contexto de la vista')
    return this.$router.push(`/${this.environmentId}/assistant`)
  }

  mounted() {
    this.$store.commit('dashboard/setMiniDrawer', true)
  }

  beforeDestroy() {
    this.$store.commit('dashboard/setEnvironmentLinkPath', null)
  }

  get overlay(): boolean {
    return this.$store.state.dashboard.designOverlay
  }

  openComponentEditor() {}
}
