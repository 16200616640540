
import { Vue, Component, Prop } from 'vue-property-decorator'
import Navbar from '../View/Navbar.vue'
import { Environment, EnvironmentUser } from '@/models'
import _cloneDeep from 'lodash/cloneDeep'
import _pick from 'lodash/pick'
import _mapKeys from 'lodash/mapKeys'
import Form from '@/components/form/Form.vue'
import Loading from '@/components/Loading.vue'
import EnvironmentSidebar from '../Sidebar.vue'
@Component({
  components: {
    Form,
    Navbar,
    Loading,
    EnvironmentSidebar
  }
})
export default class EnvironmentManage extends Vue {
  @Prop({ type: Object, required: true }) environment!: Environment
  @Prop({ type: Object, required: true }) environmentUser!: EnvironmentUser
  @Prop({ type: Boolean, default: false }) sidebar!: boolean
  @Prop({ type: Boolean, default: false }) sidebarOpen!: boolean
  @Prop({ type: String, default: '' }) path!: string
  @Prop({ type: [Number, String] }) notificationCount?: number | string
  @Prop({ type: [Number, String] }) unreadMessageCount?: number | string
  @Prop({ type: [Number, String], default: 0 }) unreadMessageCountByClient?:
    | number
    | string
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<
    string,
    string
  >
  isSidebarOpen =
    (!this.$vuetify.breakpoint.mdAndDown && !this.path) || this.path === '/'
  get ready() {
    return !!(this.environment && this.environmentUser)
  }
  get title() {
    switch (this.path.split('/')[2]) {
      case 'users':
        return 'Administración de Usuarios'
      case 'tickets':
        return 'Historial de Conversaciones'
      default:
        return 'Administración'
    }
  }
  get hasSidebar() {
    return !this.environment.allowLightweightLayout
  }
}
