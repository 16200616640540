var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{staticClass:"login"},[_c('StatusBar',{attrs:{"statusStyle":"default"}}),_c('v-main',[_c('v-container',{style:(_vm.bgStyle),attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-start":"","fill-height":""}},[(_vm.authenticating || _vm.$apollo.loading)?_c('v-flex',{staticClass:"acryllic-bg--themed e authenticating",attrs:{"xs12":""}},[_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":""}},[_c('v-card',{staticClass:"login-card",staticStyle:{"max-width":"480px"}},[(_vm.logo)?_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.logo}})]):_vm._e(),(_vm.loggedIn)?_c('v-card-text',{staticClass:"logged-in-greet text-center"},[_c('v-avatar',{attrs:{"size":64,"color":"grey lighten-4"}},[_c('img',{attrs:{"src":_vm.userAvatar,"alt":"avatar"}})]),_c('h2',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('Login.welcome'))+", "+_vm._s(_vm.user.profile.name || _vm.user.email)+"! ")]),_c('span',[_vm._v(_vm._s(_vm.$t('Login.redirecting')))])],1):_vm._e(),_c('Loading')],1)],1)],1):(_vm.domain && !_vm.environment)?_c('v-flex',{staticClass:"acryllic-bg--themed login-container",attrs:{"xs12":"","sm4":"","md3":""}},[_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":""}},[_c('v-card',{staticClass:"login-card"},[_c('v-card-text',{staticClass:"logged-in-greet text-center"},[_c('div',[_c('v-icon',{attrs:{"size":"96"}},[_vm._v("error")])],1),_c('br'),_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('Login.invalidUrl')))])])],1)],1)],1):(
            _vm.environment &&
            _vm.environment.enableCustomLoginView &&
            _vm.environment.customLoginView
          )?_c('EnvironmentView',{attrs:{"environment":_vm.environment,"environmentUser":{},"viewParams":{},"viewId":_vm.environment.customLoginView,"disableNavbar":"","hidediv":"","noCustomCSS":""}}):_c('v-flex',{staticClass:"acryllic-bg--themed login-container",attrs:{"xs12":"","sm4":"","md3":""}},[_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":""}},[_c('v-card',{staticClass:"login-card"},[(_vm.logo)?_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.logo}})]):_vm._e(),(!_vm.loggedIn)?_c('v-card-text',[(!_vm.forgot)?_c('MutationForm',{ref:"loginForm",attrs:{"mutation":"loginWithPassword","submitButtonText":_vm.$t('Login.submitButtonText'),"submitButtonIcon":"vpn_key","schema":{
                    email: {
                      label: _vm.$t('Login.schema.email.label'),
                      type: 'string',
                      fieldType: 'email',
                      fieldOptions: {
                        icon: 'person'
                      }
                    },
                    password: {
                      label: _vm.$t('Login.schema.password.label'),
                      type: 'string',
                      fieldType: 'password',
                      fieldOptions: {
                        icon: 'lock'
                      }
                    }
                  },"no-reset":""},on:{"success":_vm.loginSuccess}}):_c('MutationForm',{ref:"forgotForm",attrs:{"mutation":"forgotPassword","subSelection":" ","schema":{
                    email: {
                      label: _vm.$t('Login.schema.email.label'),
                      type: 'string',
                      fieldType: 'email',
                      fieldOptions: {
                        icon: 'person'
                      }
                    },
                    environmentId: {
                      __graphQLType: 'ID'
                    }
                  },"constants":{ environmentId: _vm.environment._id },"no-reset":""},on:{"success":_vm.forgotSuccess}})],1):_vm._e(),(_vm.environment.allowSignUp)?_c('v-card-actions',[_c('v-spacer'),(!_vm.forgot)?_c('v-btn',{attrs:{"text":"","color":"primary","to":_vm.toSignUp}},[_vm._v(_vm._s(_vm.$t('Login.createAccountBtn')))]):_vm._e()],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),(!_vm.forgot)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.forgot = true}}},[_vm._v(_vm._s(_vm.$t('Login.forgotPassBtn')))]):_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.forgot = false}}},[_vm._v(_vm._s(_vm.$t('Login.loginBtn')))])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }