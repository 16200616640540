
import {
  Vue,
  Component,
  Prop,
  Watch,
  Inject,
  Provide
} from 'vue-property-decorator'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import draggable from 'vuedraggable'
import {
  CollectionIndexesFragment,
  CollectionFragment
} from '@/components/componentTypes/collections/fragments'
import { Collection, CollectionIndex } from '@/models'
import Field from '@/components/fields/Field.vue'

import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import cleanData from '@/utils/gql/cleanData'

@Component({
  components: {
    Loading,
    Field,
    draggable
  },
  apollo: {
    savedCollection: {
      query: gql`
        query getCollection($collectionId: ID) {
          savedCollection: collection(collectionId: $collectionId) {
            _id
            ...CollectionIndexes
          }
        }
        ${CollectionIndexesFragment}
      `,
      variables() {
        return {
          collectionId: this.collectionId
        }
      }
    }
  }
})
export default class CollectionEditIndexes extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) collectionId!: string

  savedCollection: Partial<Collection> | null = null
  collection: Partial<Collection> | null = null
  saving = false

  @Provide()
  fieldValues() {
    return {
      collectionId: this.collectionId
    }
  }

  @Watch('savedCollection')
  updateCollection() {
    this.collection = _cloneDeep(this.savedCollection)
  }

  get dirty() {
    return !_isEqual(this.collection, this.savedCollection)
  }

  addIndex() {
    if (!this.collection) return
    if (!this.collection.indexes) this.collection.indexes = []
    this.collection.indexes.push({
      fields: [],
      type: 'DESC',
      unique: false
    })
  }

  removeIndex(index: number) {
    if (!this.collection?.indexes) return
    this.collection.indexes.splice(index, 1)
  }

  normalize() {
    if (!this.collection) return
    if (!this.collection.indexes) this.collection.indexes = []
    this.collection.indexes = this.collection.indexes.filter(
      (f) => f && f.fields.length && f.type
    )
  }

  getFieldsForIndex(cIndex: CollectionIndex) {
    return (cIndex.fields || []).map((f) => f.replace(/^data\./, ''))
  }

  setFieldsForIndex(cIndex: CollectionIndex, fields: string[]) {
    cIndex.fields = fields.map((f) =>
      f === '_id' || f === 'createdAt' ? f : 'data.' + f
    )
  }

  async save() {
    if (!this.collection || this.saving) return
    this.normalize()
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($collectionId: ID, $indexes: [CollectionIndexInput]) {
            setCollectionIndexes(
              collectionId: $collectionId
              indexes: $indexes
            ) {
              ...Collection
            }
          }
          ${CollectionFragment}
        `,
        // Parameters
        variables: {
          collectionId: this.collection._id,
          indexes: cleanData(this.collection, CollectionIndexesFragment).indexes
        }
      })

      this.savedCollection = result.data.setCollectionIndexes
      this.$emit('save', result.data.updateFilter)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
