
import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField, FileManagerFile } from '@/models'
import CaptureModal from './CaptureModal.vue'

interface FingerprintCapture {
  image: string
  wsq: any
}

interface FingerprintCaptureFieldOptions {
  hidden: boolean,
  rules?: Record<string, any>,
  icon?: string
  placeholder?: string,
  noicon: boolean
}
@Component({
  components: {
    CaptureModal
  }
})
export default class FingerprintCaptureField extends Vue {
  /** Current Value */
  @Prop({ type: Object }) value?: FingerprintCapture
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Rules prop */
  @Prop({type: Array, required: false}) rules!:Record<string, any>[]
  /** Rules disabled */
  @Prop({type: Boolean, default: false}) norules!: boolean
  /** Icon disabled */
  @Prop({type: Boolean, default: true}) noicon!: boolean
  /** outlined prop */
  @Prop({type: Boolean, default: true}) outlined!: boolean
  /** dense prop */
  @Prop({type: Boolean, default: true}) dense!: boolean

  captureModalOpen = false

  openModal() {
    // if (this.value) return
    this.captureModalOpen = true
  }

  get getIcon(){
    if(this.noicon || this.fieldOptions.noicon) return ''
    return this.fieldOptions.icon || 'fingerprint'
  }

  /** Validation Rules */
  get validationRules() {
    const rules = this.fieldOptions.rules || this.rules ||[]
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || this.$t('fingerPrint_field.script.validationRules.required'))
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): FingerprintCaptureFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get displayValue() {
    if (!this.value) return
    return this.$t('fingerPrint_field.script.displayValue.required')
  }

  handleSave(data: FingerprintCapture) {
    this.$emit('input', data)
  }

  clearValue() {
    this.$emit('input', null)
  }
}
