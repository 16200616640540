
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { AgentFragment, AgentUpdateFragment } from './fragments'
import { Agent } from '@/models'
import Loading from '@/components/Loading.vue'
import Fields from '@/components/form/Fields.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import cleanData from '@/utils/gql/cleanData'
import { confirmDelete } from '@/components/dialogs'
import draggable from 'vuedraggable'
import Chance from 'chance'
import micromark from 'micromark'
import Terminal from '@/components/Terminal.vue'
import c from 'ansi-colors'
import moment from '@/plugins/moment'

@Component({
  components: {
    Loading,
    Fields,
    draggable,
    Terminal
  },
  apollo: {
    savedAgent: {
      query: gql`
        query getAgent($agentId: ID) {
          savedAgent: agent(agentId: $agentId) {
            ...Agent
          }
        }
        ${AgentFragment}
      `,
      variables() {
        return {
          agentId: this.componentId
        }
      }
    },
    $subscribe: {
      agentLogInserted: {
        query: gql`
          subscription agentLogInserted($agentId: ID) {
            agentLogInserted(agentId: $agentId)
          }
        `,
        variables() {
          return {
            agentId: this.agent._id
          }
        },
        result({ data }: any) {
          const { agentLogInserted } = data
          console.log(data)
          if (agentLogInserted) {
            this.localTerminal?.terminal?.writeln(agentLogInserted)
          }
        }
      },
      globalAgentLogInserted: {
        query: gql`
          subscription globalAgentLogInserted {
            globalAgentLogInserted
          }
        `,
        result({ data }: any) {
          const { globalAgentLogInserted } = data
          console.log(data)
          if (globalAgentLogInserted) {
            this.globalTerminal?.terminal?.writeln(globalAgentLogInserted)
          }
        }
      }
    }
  }
})
export default class AgentEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String, required: true }) componentId!: String
  @Ref() readonly localTerminal!: Terminal
  @Ref() readonly globalTerminal!: Terminal

  showTerminal = false
  currentTerminal = 'local'

  testMessage = ''
  testChat: any[] = []
  testingMode = false
  launchingTest = false
  sendingTestMessage = false

  savedAgent: Agent | null = null
  agent: Partial<Agent> = {}

  saving = false

  testSessionId: string | null = null

  @Watch('savedAgent')
  updateSavedAgent(savedAgent: Agent) {
    this.$set(this, 'agent', _cloneDeep(savedAgent))
    if (this.agent.modelTemperature == null) {
      this.$set(this.agent, 'modelTemperature', 1)
    }
    if (this.agent.modelTopP == null) {
      this.$set(this.agent, 'modelTopP', 1)
    }
    if (this.agent.modelFrequencyPenalty == null) {
      this.$set(this.agent, 'modelFrequencyPenalty', 0)
    }
    if (this.agent.modelPresencePenalty == null) {
      this.$set(this.agent, 'modelPresencePenalty', 0)
    }
  }

  @Watch('agent.name')
  updateName(newName: string) {
    this.$emit('name', newName)
  }

  get dirty() {
    return !_isEqual(this.agent, this.savedAgent)
  }

  addFunction() {
    const fn = {
      uid: new Chance().guid(),
      name: '',
      description: '',
      logic: {
        params: '',
        script: ''
      }
    }
    if (!this.agent.functions) this.$set(this.agent, 'functions', [])
    this.agent.functions!.push(fn)
  }

  removeFunction(index: number) {
    if (!this.agent.functions) return
    this.agent.functions.splice(index, 1)
  }

  get visibleTestChat() {
    return this.testChat.filter((m) => ['user', 'assistant'].includes(m.role))
  }

  async startTesting() {
    this.testingMode = true
    this.launchingTest = true
    const { data } = await this.$apollo.mutate({
      mutation: gql`
        mutation startAgentSession($agentId: ID) {
          sessionId: startAgentSession(agentId: $agentId)
        }
      `,
      variables: {
        agentId: this.agent._id
      }
    })
    this.testSessionId = data.sessionId
    this.launchingTest = false
  }

  stopTesting() {
    this.testingMode = false
    this.testChat = []
    this.testSessionId = null
    this.testMessage = ''
  }

  renderMarkdown(value: string) {
    return micromark(value)
  }

  async sendMessage() {
    if (this.launchingTest || this.sendingTestMessage || !this.testSessionId)
      return
    this.sendingTestMessage = true
    const messageToSend = this.testMessage
    this.testMessage = ''
    try {
      this.testChat.push({
        uid: new Chance().guid(),
        role: 'user',
        content: messageToSend
      })
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation sendMessageToAgent($sessionId: ID, $content: String) {
            response: sendMessageToAgent(
              sessionId: $sessionId
              content: $content
            )
          }
        `,
        variables: {
          sessionId: this.testSessionId,
          content: messageToSend
        }
      })
      this.testChat.push({
        uid: new Chance().guid(),
        role: 'assistant',
        content: data.response
      })
    } catch (e) {
      console.error(e)
      const err = e as any
      this.logError({
        message:
          this.$t('agents_edit.script.send_message.error_log') +
          JSON.stringify((e && err.response && err.response.data) || e, null, 2)
      })
      this.testChat.push({
        uid: new Chance().guid(),
        role: 'assistant',
        content: this.$t('agents_edit.script.send_message.error_test_chat')
      })
    } finally {
      this.sendingTestMessage = false
    }
  }
  async save() {
    if (!this.agent || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($agentId: ID, $agent: UpdateAgentInput) {
            updateAgent(agentId: $agentId, agent: $agent) {
              ...Agent
            }
          }
          ${AgentFragment}
        `,
        // Parameters
        variables: {
          agentId: this.agent._id,
          agent: cleanData(this.agent, AgentUpdateFragment)
        }
      })

      this.savedAgent = result.data.updateAgent
      this.$emit('save', result.data.updateAgent)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  log(params: { message?: string; subject?: string }) {
    const time = moment()
    let prefix = `[${c.dim.cyan(time.format('YYYY-MM-DD@'))}${c.cyan(
      time.format('HH:mm:ss')
    )} ${c.yellow(this.agent.name!)}]`
    if (params.subject) {
      prefix += ` <${c.green(params.subject)}>`
    }
    const msg = `${prefix} ${params.message}`
    this.localTerminal?.terminal?.writeln(msg)
    this.globalTerminal?.terminal?.writeln(msg)
    console.log(params.message)
  }

  logError(params: { message?: string; subject?: string }) {
    const time = moment()
    let prefix = `[${c.dim.red(time.format('YYYY-MM-DD@'))}${c.red(
      time.format('HH:mm:ss')
    )} ${c.yellow(this.agent.name!)}]`
    if (params.subject) {
      prefix += ` <${c.green(params.subject)}>`
    }
    const msg = `${prefix} ${params.message}`
    this.localTerminal?.terminal?.writeln(msg)
    this.globalTerminal?.terminal?.writeln(msg)
    console.error(params.message)
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        this.$t('agents_edit.script.delete.confirm').toString()
      ))
    )
      return
    if (!this.agent || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($agentId: ID) {
            deleteAgent(agentId: $agentId)
          }
        `,
        // Parameters
        variables: {
          agentId: this.agent._id
        }
      })
      this.$emit('delete', result.data.deleteAgent)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
