var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:(_vm.bindedStyles.mainContainer),attrs:{"id":"mainContainer"}},[(_vm.$apollo.loading || !_vm.chart._id)?_c('Loading'):[_c('v-card-actions',{staticClass:"overflow-x-auto"},[(_vm.currentTab === 'design')?[_c('v-btn',{staticClass:"rounded-sm px-4",style:({ border: `2px solid ${_vm.primaryColor}` }),attrs:{"outlined":""},on:{"click":function($event){_vm.previewParametersOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('charts_edit.paramsBtn'))+" ")])]:_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"rounded-sm",staticStyle:{"min-width":"0"},attrs:{"color":"error","title":_vm.$t('charts_edit.deleteTitle'),"height":"36","width":"36","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete_outline")])],1),_c('v-btn',{staticClass:"rounded-sm px-4",style:({ border: `2px solid ${_vm.primaryColor}` }),attrs:{"outlined":"","title":_vm.$t('charts_edit.undoTitle'),"disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")]),_vm._v(" "+_vm._s(_vm.$t('charts_edit.undoBtn'))+" ")],1),_c('v-btn',{staticClass:"rounded-sm px-4",attrs:{"color":"primary","disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('charts_edit.saveBtn'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("save")])],1)],2),_c('v-divider'),_c('v-tabs-items',{staticStyle:{"background-color":"transparent"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{style:(_vm.bindedStyles.content),attrs:{"value":"component"}},[_c('Fields',{attrs:{"schema":{
            name: {
              type: 'string',
              label: _vm.$t('charts_edit.schema.name.label'),
              description: _vm.$t('charts_edit.schema.name.description'),
              sizeSmall: 12,
              newDesign: true
            },
            title: {
              type: 'string',
              label: _vm.$t('charts_edit.schema.title.label'),
              description: _vm.$t('charts_edit.schema.title.description'),
              sizeSmall: 12,
              optional: true,
              newDesign: true
            },
            roles: {
              type: 'roleSelect',
              __graphQLType: '[ID]',
              label: _vm.$t('charts_edit.schema.roles.label'),
              fieldOptions: {
                multi: true
              },
              optional: true,
              newDesign: true
            },
            chartTypeId: {
              type: 'select',
              __graphQLType: 'ID',
              label: _vm.$t('charts_edit.schema.chartTypeId.label'),
              fieldOptions: {
                options: _vm.availableChartTypes
              },
              newDesign: true
            },
            collectionId: {
              type: 'collectionSelect',
              label: _vm.$t('charts_edit.schema.collectionId.label'),
              newDesign: true
            },
            disableRefresh: {
              type: 'boolean',
              fieldType: 'checkbox',
              label: _vm.$t('charts_edit.schema.disableRefresh.label'),
              optional: true,
              newDesign: true
            }
          },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.chart),callback:function ($$v) {_vm.chart=$$v},expression:"chart"}}),(_vm.chartType)?[_c('v-container',{staticClass:"pt-0"},[_c('v-slide-y-transition',{attrs:{"group":""}},[(_vm.chart.collectionId)?_c('ComponentSelect',{key:"filtersIds",attrs:{"name":"filtersIds","schema":{
                  label: _vm.$t('charts_edit.schema.filtersIds.label'),
                  fieldOptions: {
                    multi: true,
                    componentTypeName: 'filters',
                    filterByKey: 'collectionId',
                    filterByValue: _vm.chart.collectionId
                  },
                  newDesign: true
                },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId }},model:{value:(_vm.chart.filtersIds),callback:function ($$v) {_vm.$set(_vm.chart, "filtersIds", $$v)},expression:"chart.filtersIds"}}):_vm._e(),(
                  _vm.chart.collectionId &&
                  _vm.chart.filtersIds &&
                  _vm.chart.filtersIds.length > 1
                )?_c('ComponentSelect',{key:"filterByDefault",attrs:{"name":"filterByDefault","schema":{
                  label: _vm.$t('charts_edit.schema.filterByDefault.label'),
                  fieldOptions: {
                    componentTypeName: 'filters',
                    filterByKey: '_id',
                    filterByValue: _vm.chart.filtersIds
                  }
                },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId }},model:{value:(_vm.chart.filterByDefault),callback:function ($$v) {_vm.$set(_vm.chart, "filterByDefault", $$v)},expression:"chart.filterByDefault"}}):_vm._e(),(_vm.chart.filtersIds && _vm.chart.filtersIds.length)?_c('v-checkbox',{key:"allowsNoFilter",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"foreground--text"},[_vm._v(_vm._s(_vm.$t('charts_edit.noUseFilter')))])]},proxy:true}],null,false,3122498028),model:{value:(_vm.chart.allowsNoFilter),callback:function ($$v) {_vm.$set(_vm.chart, "allowsNoFilter", $$v)},expression:"chart.allowsNoFilter"}}):_vm._e()],1)],1)]:_vm._e()],2),_c('v-tab-item',{attrs:{"value":"design"}},[(_vm.chartType)?_c('div',[_c('div',{staticClass:"background pa-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('charts_edit.preview')))]),_c('ChartResult',{attrs:{"chart":_vm.savedChart,"preview":true,"viewParams":_vm.chart.previewParameters,"filterId":_vm.previewFilter.filterId,"filterOptions":_vm.previewFilter.filterOptions,"previewOptions":_vm.previewOptions},on:{"setParams":function($event){return _vm.$emit('setParams', $event)}}})],1),_c('div',{style:(_vm.bindedStyles.content)},[_c('OptionFields',{attrs:{"schema":_vm.chartType.optionsParams,"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{
                environmentId: _vm.environmentId,
                collectionId: _vm.chart.collectionId
              },"validationErrors":{},"allowed-value-types":['fixed']},on:{"input":_vm.updatePreviewOptions},model:{value:(_vm.chart.options),callback:function ($$v) {_vm.$set(_vm.chart, "options", $$v)},expression:"chart.options"}})],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.previewParametersOpen),callback:function ($$v) {_vm.previewParametersOpen=$$v},expression:"previewParametersOpen"}},[_c('v-card',{staticClass:"card--flex-toolbar"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v("build")]),_c('div',{staticClass:"title"},[_c('h1',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('charts_edit.params')))])]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){_vm.previewParametersOpen = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-container',{staticStyle:{"overflow-y":"auto","max-height":"calc(90vh - 128px)"}},[_c('v-card-text',[_c('h2',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('charts_edit.prevParams')))]),_c('br'),_vm._v(" "+_vm._s(_vm.$t('charts_edit.paramsDescrip'))+" "),_c('KeyValueList',{attrs:{"keyLabel":_vm.$t('charts_edit.params')},model:{value:(_vm.chart.previewParameters),callback:function ($$v) {_vm.$set(_vm.chart, "previewParameters", $$v)},expression:"chart.previewParameters"}})],1)],1)],1)],1)],1):_c('v-card-text',[_c('v-icon',{attrs:{"size":"48"}},[_vm._v("poll")]),_c('br'),_c('div',[_vm._v(_vm._s(_vm.$t('charts_edit.selectGraphic')))])],1)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }