
import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({
  components: {},
})
export default class NotificationsButton extends Vue {
  @Prop({ type: String, default: 'primary' }) buttonColor!: string
  @Prop({ type: Object, default: () => ({}) }) shadowStyle!: object
  get environment() {
    return this.$store.state.environment?.environment
  }
  
  get notificationCount() {
    return this.$store.state.app.notificationCount
  }
  openNotifications() {
    this.$store.commit('app/updateNotifications', true)
  }
}
