
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import * as monaco from 'monaco-editor'
import MonacoEditor, { editorEnv } from '@/plugins/monaco'

import getNodeAtLocation from '@/utils/parse5/getNodeAtLocation'
import AttributeSections from './attrSections'
import Field from '@/components/fields/Field.vue'

let parse5: any = null

@Component({
  components: {
    MonacoEditor,
    Field
  }
})
export default class VueTemplateEditor extends Vue {
  @Prop({ type: String, required: true }) value!: any
  @Prop({ type: String, required: true }) environmentId!: string

  parsedTemplate: any = {}
  editorPosition = 0
  savedPosition = 0
  currentTab = 0
  openSections = Array(100)
    .fill(0)
    .map((_, i) => i)
  editor: any = null
  parserInitialized = false

  get template() {
    return this.value
  }

  set template(value) {
    this.$emit('input', value)
  }

  get propertiesWindowEnabled() {
    return this.$store.state.experiments.active['vue-properties-window']
  }

  get selectedNode() {
    const r =
      getNodeAtLocation(this.parsedTemplate, this.editorPosition) || null
    return r
  }

  get attributeSections() {
    if (!this.selectedNode) return []
    return AttributeSections.filter((s) =>
      s.test.test(this.selectedNode.nodeName)
    )
  }

  get propAttributes() {
    return this.attributeSections.map((s) => s.attrs).flat()
  }

  get eventAttributes() {
    return this.attributeSections.map((s) => s.events || []).flat()
  }

  @Watch('propertiesWindowEnabled', { immediate: true })
  onPropertiesWindowToggle() {
    if (!this.propertiesWindowEnabled) {
      return
    }
    import(
      // @ts-ignore
      /* webpackIgnore: true */ 'https://pkg.sodlab.com/parse5.min.mjs'
    ).then((module) => {
      parse5 = module
      this.parserInitialized = true
      this.parseTemplate()
    })
  }

  codeEditorOptions = {
    automaticLayout: true
  }

  @Watch('template')
  parseTemplate() {
    if (!this.parserInitialized) return
    this.parsedTemplate = this.propertiesWindowEnabled
      ? parse5.parseFragment(this.template, { sourceCodeLocationInfo: true })
      : {}
    console.log(this.parsedTemplate)
  }

  @Watch('propertiesWindowEnabled')
  onPropertiesWindowEnabledChange() {
    this.parseTemplate()
  }

  editorDidMount(editor: monaco.editor.IStandaloneCodeEditor) {
    editorEnv.environmentId = this.environmentId
    this.editor = editor
    // Options
    const model = editor.getModel()
    model?.updateOptions({
      tabSize: 2,
      insertSpaces: true
    })

    // Actions
    editor.addAction({
      id: 'save',
      label: String(this.$t('vueBlock_create.schema.newName.label')),
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S],
      run: () => {
        this.$emit('save')
      }
    })

    editor.onDidChangeCursorPosition(() => {
      if (this.savedPosition) {
        const pos = model?.getPositionAt(this.savedPosition)
        if (pos) editor.setPosition(pos)
        this.savedPosition = 0
        return
      }
      const pos = editor.getPosition()
      this.editorPosition = pos ? model?.getOffsetAt(pos) || 0 : 0
    })
    // Events
    editorEnv.onEditComponent = (componentType, componentId) => {
      this.$emit('editComponent', { componentType, componentId })
    }
  }

  serializeHtml() {
    if (!this.parserInitialized) return
    this.savedPosition = this.editorPosition
    this.template = parse5.serialize(this.parsedTemplate).replace(/=""/g, '')
    this.editor.trigger('editor', 'editor.action.formatDocument', null)
  }

  normalizeAttributeName(name: string) {
    return name.replace(/^(v-bind:|v-on:|@|:)/, '')
  }

  processEvents(events: string[]) {
    return events.map((e) => {
      const attr = this.selectedNode.attrs.find(
        (at: any) => this.normalizeAttributeName(at.name) === e
      )
      let value = attr ? attr.value : null
      let color = value ? 'green' : 'transparent'
      return {
        name: e,
        attr,
        value,
        isEvent: true,
        color
      }
    })
  }

  processAttrs(attrs: Record<string, any>) {
    return Object.keys(attrs).map((a) => {
      const attr = this.selectedNode.attrs.find(
        (at: any) => this.normalizeAttributeName(at.name) === a
      )
      let isBind =
        attr && (attr.name.startsWith('v-bind:') || attr.name.startsWith(':'))
      let value = attr ? attr.value : null
      if (
        !isBind &&
        (attrs[a].type === 'checkbox' || attrs[a].type === 'boolean')
      ) {
        value = attr && attr.value === ''
      }

      let color = 'transparent'
      if (isBind) color = 'orange'
      else {
        if (value) color = 'white'
        if (a === 'v-model') color = 'red'
      }
      return {
        schema: attrs[a],
        name: a,
        attr,
        value,
        isBind,
        color
      }
    })
  }

  updateAttrValue(attr: any, value: any) {
    let valueToInsert = value
    if (value === true) valueToInsert = ''
    if (typeof value === 'number') valueToInsert = value.toString()
    if (attr.attr) {
      if (attr.attr.value === valueToInsert) return
      attr.attr.value = valueToInsert
      console.log(value)
      if (
        value === null ||
        value === false ||
        value === undefined ||
        (attr.isEvent && !value)
      ) {
        this.selectedNode.attrs = this.selectedNode.attrs.filter(
          (a: any) => a.name !== attr.attr.name
        )
      }
    } else {
      this.selectedNode.attrs.push({
        name: attr.isEvent ? '@' + attr.name : attr.name,
        value: valueToInsert
      })
    }
    this.serializeHtml()
  }

  toggleBind(attr: any) {
    if (attr.attr) {
      if (attr.attr.name.startsWith('v-bind:')) {
        attr.attr.name = attr.attr.name.replace(/^v-bind:/, '')
      } else if (attr.attr.name.startsWith(':')) {
        attr.attr.name = attr.attr.name.replace(/^:/, '')
      } else {
        attr.attr.name = `:${attr.attr.name}`
      }
    } else {
      attr.attr = {
        name: `:${attr.name}`,
        value: ''
      }
      this.selectedNode.attrs.push(attr.attr)
    }
    this.serializeHtml()
  }

  executeAction({ action, options }: any) {
    switch (action) {
      case 'insertChild':
        this.selectedNode.childNodes.push(options)
        this.serializeHtml()
        break
    }
  }
}
