
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Chart } from '@/models'
import {
  VueApexCharts as ApexChart,
  ApexCharts,
  es
} from '@/plugins/apexcharts'
import numeral from '@/plugins/numeral'

@Component({
  components: {
    ApexChart
  }
})
export default class MixedChart extends Vue {
  @Prop({ type: Object, required: true }) chart!: Chart
  @Prop({ type: Object, required: true }) result!: any
  @Prop({ type: Object, default: () => ({}) }) queryOptions!: Record<
    string,
    any
  >
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Boolean, default: false }) loading!: boolean

  get chartOptions(): ApexCharts.ApexOptions {
    const o = (this.result && this.result.chartOptions) || {}
    return {
      ...o,
      chart: {
        ...o.chart,
        locales: [es],
        defaultLocale: 'es'
      },
      xaxis: {
        ...o.xaxis,
        labels: {
          style: {
            colors: this.$vuetify.theme.dark ? '#EEEEEE' : []
          }
        }
      },
      tooltip: {
        ...o.tooltip,
        theme: this.$vuetify.theme.dark ? 'dark' : 'light'
      },
      yaxis: o.yaxis.map((y: any) => ({
        ...y,
        labels: {
          ...y.labels,
          formatter: (v: number, o: any) => {
            switch (y.dataType) {
              case 'number':
                return numeral(v).format('0,0.[00]') || v
              case 'money':
              case 'currency':
                return numeral(v).format('$0,0.[00]') || v
              case 'percentage':
                return numeral(v).format('0,0.[00]%') || v
              default:
                return v
            }
          }
        }
      }))
    }
  }

  get series() {
    return (this.result && this.result.series) || []
  }
}
