
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Hook, HookFunctionType } from '@/models'
import { HookFragment } from './fragments'
import Loading from '@/components/Loading.vue'
import HookEditLegacy from './EditLegacy.vue'
import gql from 'graphql-tag'
import { ApolloQueryResult } from 'apollo-client'

@Component({
  components: {
    Loading,
    HookEditLegacy
  },
  apollo: {
    savedHook: {
      query: gql`
        query getHook($hookId: ID) {
          savedHook: hook(hookId: $hookId) {
            ...Hook
          }
        }
        ${HookFragment}
      `,
      variables() {
        return {
          hookId: this.componentId
        }
      },
      manual: true,
      result(r: ApolloQueryResult<{ savedHook: Hook }>) {
        this.savedHook = {
          ...r.data.savedHook,
          options: r.data.savedHook.options || {}
        }
      }
    }
  }
})
export default class HookEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String, required: true }) componentId!: String

  savedHook: Readonly<Hook> | null = null

  handleSave(hook: Hook) {
    this.savedHook = hook
    this.$emit('save', hook)
  }
}
