
import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface EmailFieldOptions {
  placeholder?: string
  icon?: string
}

@Component({})
export default class EmailField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Rules prop */
  @Prop({ type: Array, required: false }) rules!: Record<string, any>[]
  /** Rules disabled */
  @Prop({ type: Boolean, default: false }) norules!: boolean
  /** Icon disabled */
  @Prop({ type: Boolean, default: true }) noicon!: boolean
  /** outlined prop */
  @Prop({ type: Boolean, default: true }) outlined!: boolean
  /** dense prop */
  @Prop({ type: Boolean, default: true }) dense!: boolean

  get getIcon() {
    if (this.noicon) return ''
    return this.fieldOptions.icon || 'email'
  }

  /** Validation Rules */
  get validationRules() {
    const rules = this.rules || []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || this.$t('email_field.script.validationRules.required'))
    }
    // Email Validation
    rules.push(
      (v?: string) => !v || /\S+@\S+\.\S+/.test(v) || this.$t('email_field.script.validationRules.invalid')
    )
    return rules
  }

  /** Additional field options */
  get fieldOptions(): EmailFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Input event handler */
  handleInput(...args: string[]) {
    this.$emit('input', ...args)
  }
}
