
import { Component, Vue, Prop } from 'vue-property-decorator'
import { FileManagerFile, FormField } from '@/models'

interface SignatureCapture {
  image: string
  ink: string
}

interface SignatureCaptureFieldOptions {
  hidden: boolean
}
@Component({})
export default class SignatureCaptureFieldView extends Vue {
  @Prop({ type: Object, default: () => ({}) }) value!: SignatureCapture
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField

  get url() {
    if (!this.value || this.fieldOptions.hidden) return ''
    return this.value.image
  }

  /** Additional field options */
  get fieldOptions(): SignatureCaptureFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }
}
