
import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField, FileManagerFile } from '@/models'
import UploadModal from './UploadModal.vue'
import _pick from 'lodash/pick'

interface FileFieldOptions {
  fileType: string
  thumbnail: boolean
  placeholder?: string
  isForPhotos?: boolean
}
@Component({
  components: {
    UploadModal
  }
})
export default class FileField extends Vue {
  /** Current Value */
  @Prop({ type: Array }) value?: FileManagerFile[]
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** No icon */
  @Prop({ type: Boolean, default: false }) noicon!: boolean
  uploadModalOpen = false

  openModal() {
    // if (this.value) return
    this.uploadModalOpen = true
  }


  get getIcon(){

    if(this.noicon) return ''
    return 'attach_file'

  }
  /** Validation Rules */
  get validationRules() {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || this.$t('fileMultiple_field.script.validationRules.required'))
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): FileFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get displayValue() {
    if (!this.value || this.value.length <= 0) return
    return `${this.value.length} ${this.$t('fileMultiple_field.script.displayValue.text')}`
  }

  get fileIcon() {
    if (!this.value) return ''
    return 'description'
  }

  handleFileSelect(files: FileManagerFile[]) {
    this.$emit(
      'input',
      files.map((file) =>
        _pick(file, [
          '_id',
          'name',
          'type',
          'size',
          'url',
          'key',
          'bucket',
          'status',
          'createdBy',
          'createdAt'
        ])
      )
    )
  }

  clearValue() {
    this.$emit('input', [])
  }
}
