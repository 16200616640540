
import gql from 'graphql-tag'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ApiEditorCreate extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string

  name = ''
  path = ''
  method = 'GET'
  creating = false
  nameErrors: string[] = []
  pathErrors: string[] = []

  availableMethods = ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'ALL']

  async create() {
    this.creating = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation createApiEndpoint(
            $environmentId: ID
            $name: String
            $method: String
            $path: String
          ) {
            createApiEndpoint(
              environmentId: $environmentId
              name: $name
              method: $method
              path: $path
            ) {
              _id
            }
          }
        `,
        variables: {
          environmentId: this.environmentId,
          name: this.name,
          method: this.method,
          path: this.path
        }
      })
      const { _id: apiEndpointId } = data.createApiEndpoint
      this.$emit('created')
      this.$router.push({
        name: 'adminApiEdit',
        params: {
          environmentId: this.environmentId,
          componentId: apiEndpointId
        }
      })
    } catch (e) {
      console.error(e)
      if (e.graphQLErrors) {
        e.graphQLErrors.forEach((err: any) => {
          if (err.error === 'validationError') {
            this.nameErrors = [this.$t('endpoint_fullCreate.script.create.unique').toString()/* 'Debe ser único' */]
          }
        })
      }
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: this.$t('endpoint_fullCreate.script.create.error')/* 'Ocurrió un error al intentar crear el endpoint.' */
      })
    } finally {
      this.creating = false
    }
  }

  get methodColor() {
    return (
      {
        GET: 'cyan',
        HEAD: 'blue-grey',
        POST: 'green',
        PUT: 'purple',
        DELETE: 'red',
        PATCH: 'pink',
        ALL: 'primary'
      } as Record<string, string>
    )[this.method]
  }
}
