
import { Component, Vue } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import { User } from '../../models'
import AdminNavbar from '@/components/AdminNavbar.vue'
import Environments from './Environments.vue'
//import Users from './Users.vue'
import Users from './Users.vue'
import DashboardAppBar from '@/components/DashboardAppBar.vue'

@Component({
  components: {
    Loading,
    AdminNavbar,
    Environments,
    Users,
    DashboardAppBar
  }
})
export default class Home extends Vue {
  tab = 'environments'

  mounted() {
    const style = document.getElementById('customCSS') as HTMLStyleElement
    style.innerText = ''
    /*this.$vuetify.theme.themes.light.primary =
      this.$vuetify.theme.themes.light.secondary =
      this.$vuetify.theme.themes.light.accent =
        '#808080'
    this.$vuetify.theme.themes.dark.primary =
      this.$vuetify.theme.themes.dark.secondary =
      this.$vuetify.theme.themes.dark.accent =
        '#808080'*/
        
      this.$vuetify.theme.themes.dark.primary = this.$vuetify.theme.themes.light.primary = {
        base: '#1648FF',
        darken1: '#002DFF',
        darken2: '#002AE1',
        darken3: '#011CB2',
        darken4: '#01138C',
        lighten1: '#407BFF',
        lighten2: '#76AFFF',
        lighten3: '#A9D0FF',
        lighten4: '#CFE7FF',
        lighten5: '#E5F2FF',
      }
  }

  get loggedIn(): boolean {
    return this.$store.getters['auth/loggedIn']
  }

  get user(): User {
    return this.$store.state.auth.user
  }

  get userAvatar(): string {
    return this.$store.getters['auth/userAvatarURL']
  }

  get userRoleDisplay(): string {
    if (this.user.roles.includes('superAdmin')) {
      return 'Súper Administrador'
    }
    if (this.user.roles.includes('admin')) {
      return 'Administrador'
    }
    return 'Usuario'
  }

  get superAdminRole() {
    return this.user.roles.includes('superAdmin')
  }
}
