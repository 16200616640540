
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import gql from 'graphql-tag'
import CodelessLoading from '@/components/CodelessLoading.vue'
import CreationFlowAssisted from './flow/assisted.vue'
import CreationFlowSelectUseCases from './flow/selectUseCases.vue'
import CreationFlowSelectSubCases from './flow/selectSubCases.vue'
import CreationFlowEditViewSubCases from './flow/editViewSubCases.vue'
import { AiAssistantContext } from '@/models/AiAssistantContext'
import { Environment } from '@/models'
import _cloneDeep from 'lodash/cloneDeep'
import { Debounce } from '@/utils/decorators'

@Component({
  components: {
    CodelessLoading,
    CreationFlowAssisted,
    CreationFlowSelectUseCases,
    CreationFlowSelectSubCases,
    CreationFlowEditViewSubCases
  },
  apollo: {
    environment: {
      query: gql`
        query getEnvironment($environmentId: ID) {
          environment(environmentId: $environmentId) {
            _id
            aiAssistantContext
          }
        }
      `,

      variables() {
        return {
          environmentId: this.environmentId
        }
      },
      fetchPolicy: 'network-only'
    }
  }
})
export default class AdminAssistant extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  environment: Partial<Environment | null> = null

  ctx: AiAssistantContext = {
    currentStep: 'assisted',
    flowType: 'blank',
    environmentName: '',
    environmentDescription: '',
    useCases: [],
    sitemapItems: [],
    dbTables: [],
    activeUseCaseIndex: -1
  }

  @Watch('environment', { immediate: true })
  onEnvironmentChange() {
    if (this.ctx.flowType !== 'blank') return
    if (this.environment?.aiAssistantContext) {
      this.ctx = _cloneDeep(this.environment.aiAssistantContext)
      this.ctx.flowType = 'assisted'
    }
  }

  @Watch('ctx', { deep: true })
  @Debounce(1000)
  onContextUpdate() {
    return this.$apollo.mutate({
      mutation: gql`
        mutation setEnvironmentAiAssistantContext(
          $environmentId: ID!
          $aiAssistantContext: JSON!
        ) {
          setEnvironmentAiAssistantContext(
            environmentId: $environmentId
            aiAssistantContext: $aiAssistantContext
          ) {
            _id
          }
        }
      `,
      variables: {
        environmentId: this.environmentId,
        aiAssistantContext: this.ctx
      }
    })
  }
}
