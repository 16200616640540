var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-tabs',{staticStyle:{"border-bottom":"1px solid var(--v-foreground-5-base)"},attrs:{"hide-slider":"","active-class":"primary white--text","background-color":"transparent","height":"36"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t('agents_create.createTab')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('agents_create.duplicateTab')))])],1),_c('v-tabs-items',{staticStyle:{"background-color":"transparent"},attrs:{"dark":_vm.isDark},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card-text',{staticStyle:{"padding":"0px"}},[_c('MutationForm',{attrs:{"no-reset":"","mutation":"createAgent","constants":{ environmentId: _vm.environmentId },"submitButtonText":"Crear","submitButtonIcon":"add","schema":{
            environmentId: {
              __graphQLType: 'ID'
            },
            name: {
              label: _vm.$t('agents_create.schema.name.label'),
              type: 'string',
              description: _vm.$t('agents_create.schema.name.description'),
              newDesign: true
            },
            displayName: {
              label: _vm.$t('agents_create.schema.display_name.label'),
              type: 'string',
              newDesign: true
            },
            description: {
              label: _vm.$t('agents_create.schema.description.label'),
              type: 'string',
              optional: true,
              newDesign: true
            }
          }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{staticClass:"rounded-sm",staticStyle:{"border":"2px solid var(--v-primary-base)"},attrs:{"slot":"left-buttons","outlined":""},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v(_vm._s(_vm.$t('agents_create.cancelBtn')))])],1)],1)],1),_c('v-tab-item',[_c('v-card-text',{staticClass:"pa-0"},[_c('MutationForm',{attrs:{"no-reset":"","mutation":"duplicateAgent","environmentVariables":{ environmentId: _vm.environmentId },"submitButtonText":"Duplicar","submitButtonIcon":"content_copy","schema":{
            agentId: {
              __graphQLType: 'ID',
              label: _vm.$t('agents_create.duplicate.schema.agent_id.label'),
              fieldType: 'componentSelect',
              fieldOptions: {
                componentTypeName: 'agents'
              },
              newDesign: true
            },
            newName: {
              label: _vm.$t('agents_create.duplicate.schema.new_name.label'),
              type: 'string',
              description: _vm.$t(
                'agents_create.duplicate.schema.new_name.description'
              ),
              newDesign: true
            },
            newDisplayName: {
              label: _vm.$t(
                'agents_create.duplicate.schema.new_display_name.label'
              ),
              type: 'string',
              newDesign: true
            }
          }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{staticClass:"rounded-sm",staticStyle:{"border":"2px solid var(--v-primary-base)"},attrs:{"slot":"left-buttons","outlined":""},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v(_vm._s(_vm.$t('agents_create.cancelBtn')))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }