
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Chart } from '@/models'
import { Chart as Chartjs, registerables } from 'chart.js'

@Component({
  components: {}
})
export default class MixedChartjs extends Vue {
  @Prop({ type: Object, required: true }) chart!: Chart
  @Prop({ type: Object, required: true }) result!: any
  @Prop({ type: Object, default: () => ({}) }) queryOptions!: Record<
    string,
    any
  >
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Boolean, default: false }) loading!: boolean

  myChart: any = null

  mounted() {
    this.renderChart()
  }

  @Watch('result')
  renderChart() {
    Chartjs.register(...registerables)
    if (this.myChart) this.myChart.destroy()
    const chartjsCanvas = this.$refs.chartjsCanvas as HTMLCanvasElement
    this.myChart = new Chartjs(chartjsCanvas, {
      type: this.type,
      data: this.chartData,
      options: this.chartOptions
    })
  }

  get chartOptions() {
    const options = this.result.options || {}
    // TODO: Find a better way
    if (options.scales) {
      let scalesList = Object.keys(options.scales)
      for (let i = 0; i < scalesList.length; i++) {
        // tslint:disable-next-line:no-eval
        if (
          options.scales[scalesList[i]].ticks &&
          options.scales[scalesList[i]].ticks.callback
        )
          options.scales[scalesList[i]].ticks.callback = eval(
            options.scales[scalesList[i]].ticks.callback
          )
      }
    }
    // tslint:disable-next-line:no-eval
    if (
      options.plugins &&
      options.plugins.tooltip &&
      options.plugins.tooltip.external
    )
      options.plugins.tooltip.external = eval(options.plugins.tooltip.external)
    return options
  }

  get chartData() {
    const data = { labels: this.result.labels, datasets: this.result.datasets }
    return data
  }

  get height() {
    if (window.innerWidth < 500) {
      return (this.result && this.result.heightMobile) || '400'
    } else {
      return (this.result && this.result.height) || '200'
    }
  }

  get type() {
    return (this.result && this.result.type) || []
  }
}
