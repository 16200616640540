
import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface TextAreaFieldOptions {
  icon?: string
  validateCaseInsensitive?: boolean
  validateCustomError?: string
  placeholder?: string
  max?: number
  norules?: boolean
  rules?: Record<string, any>
  validatePattern?: string
}

@Component({})
export default class TextAreaField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Rules prop */
  @Prop({ type: Array, required: false }) rules!: Record<string, any>[]
  /** Rules disabled */
  @Prop({ type: Boolean, default: false }) norules!: boolean
  /** Icon disabled */
  @Prop({ type: Boolean, default: false }) icon!: boolean
  /** outlined prop */
  @Prop({ type: Boolean, default: true }) outlined!: boolean
  /** dense prop */
  @Prop({ type: Boolean, default: true }) dense!: boolean

  get getIcon() {
    if (this.icon || this.fieldOptions.icon) {
      return this.fieldOptions.icon || 'text_format'
    }
    return ''
  }

  /** Validation Rules */
  get validationRules() {
    if (this.norules || this.fieldOptions.norules) return []

    const rules = this.fieldOptions.rules || this.rules || []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || 'Campo requerido.')
    }
    // Max length validation
    if (this.fieldOptions.max) {
      rules.push(
        (v?: string) =>
          !v || v.length <= this.fieldOptions.max! || 'Demasiado largo'
      )
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): TextAreaFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Input event handler */
  handleInput(...args: any[]) {
    this.$emit('input', ...args)
  }
}
