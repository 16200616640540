
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import MapContainer from './openLayers/MapContainer.vue'
import { Chart } from '@/models'

@Component({
  components: {
    MapContainer
  }
})
export default class OpenLayers extends Vue {
  @Prop({ type: Object, required: true }) chart!: Chart
  @Prop({ type: Object, required: true }) result!: any
  @Prop({ type: Object, default: () => ({}) }) queryOptions!: Record<
    string,
    any
  >
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Boolean, default: false }) loading!: boolean

  zoom = 0
  center = [0, 0]
  rotation = 0
  layers = [] // Este si debe ser un arreglo [{}, {}]
  drawMenu = false
  initialConfig = {}

  addParamMapSelections(v: any) {
    this.$emit('setParams', { _mapSelections: JSON.stringify(v) })
  }

  @Watch('result', { immediate: true })
  updateMap() {
    this.center = (this.result.initialCoordinates || '-70.65, -33.45')
      .split(',')
      .map((p: any) => parseFloat(p.trim()))
    this.drawMenu = this.result.drawMenu || false
    this.zoom = this.result.initialZoom
    this.layers = this.result.layers || []
    this.initialConfig = this.result.initialConfig || {}
  }
}
