var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-main',{staticClass:"mb-10"},[_c('v-container',{class:{ 'px-12': _vm.$vuetify.breakpoint.smAndUp },attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center justify-space-between mb-7"},[_c('v-col',{staticClass:"d-flex",class:{ 'pb-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","sm":"auto"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" dashboard ")]),_c('h1',{staticClass:"mr-3 text-h6",staticStyle:{"font-family":"'Aspira Wide Regular', sans-serif"}},[_vm._v(" "+_vm._s(_vm.$t('admin_index.title'))+" ")]),_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","dense":""}},'v-icon',_vm.$store.state.app.darkMode ? {} : { color: 'primary' },false),on),[_vm._v(" info ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('admin_index.description')))])])],1),_c('v-col',{class:{ 'pb-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{staticClass:"my-0 rounded-sm",attrs:{"block":"","color":"primary"},on:{"click":function($event){_vm.createModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('admin_index.createBtn'))+" ")])],1)],1),_c('v-card',{staticClass:"rounded-sm pa-0",style:({
        backgroundColor: _vm.$vuetify.theme.currentTheme['background-2'],
        border: `1px solid ${_vm.$vuetify.theme.currentTheme['background-5']}`
      }),attrs:{"elevation":"0","height":"calc(100vh - 120px)"}},[_c('PaginatedList',{staticStyle:{"background-color":"transparent"},attrs:{"squareIcons":"","searchBox":"","queryName":"views","defaultIcon":"apps","queryFields":"{\n                _id\n                name\n                path\n              }","searchQuery":_vm.searchQuery,"queryVariables":{
          environmentId: {
            type: 'ID',
            value: _vm.environmentId
          }
        },"limit":50,"transformResult":_vm.transformResult},on:{"addButtonClick":_vm.toggleCreateModal}})],1),_c('ComponentCreateDialog',{attrs:{"width":800,"environmentId":_vm.environmentId,"componentTypeName":"views"},on:{"create":_vm.handleCreate},model:{value:(_vm.createModalOpen),callback:function ($$v) {_vm.createModalOpen=$$v},expression:"createModalOpen"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }