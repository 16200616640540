
import { VueBlock } from '@/models'
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'

@Component({})
export default class VueBlockEditorSidebar extends Vue {
  @Prop({ type: Object, required: true }) block!: VueBlock
  @PropSync('activeTab', { type: String, required: true })
  activeSection!: string

  mainSections = [
    {
      key: 'options',
      icon: 'build',
      title: this.$t('fullview_editSide.script.mainSections.options.title'),
      subtitle: this.$t(
        'fullview_editSide.script.mainSections.options.subtitle'
      )
    },
    {
      key: 'template',
      icon: 'polymer',
      title: this.$t('fullview_editSide.script.mainSections.template.title'),
      subtitle: this.$t('fullview_editSide.script.mainSections.template.title')
    },
    {
      key: 'script',
      icon: 'memory',
      title: this.$t('fullview_editSide.script.mainSections.script.title'),
      subtitle: this.$t('fullview_editSide.script.mainSections.script.subtitle')
    },
    {
      key: 'style',
      icon: 'palette',
      title: this.$t('fullview_editSide.script.mainSections.style.title'),
      subtitle: this.$t('fullview_editSide.script.mainSections.style.subtitle')
    },
    {
      key: 'data',
      icon: 'integration_instructions',
      title: this.$t('fullview_editSide.script.mainSections.data.title'),
      subtitle: this.$t('fullview_editSide.script.mainSections.data.subtitle')
    }
  ]
}
