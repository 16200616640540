
import { Vue, Component, Prop } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { Block, FormField, Hook, PaginatedQueryResult } from '@/models'
import { BlockFragment } from '@/components/componentTypes/blocks/fragments'
import ComponentSelectField from '@/components/fields/componentSelect/Field.vue'

@Component({
  components: {
    ComponentSelectField
  }
})
export default class TableSelectField extends Vue {
  /** Current Value */
  @Prop({ type: [String, Array], default: () => '' }) value!: string[] | string
  /** Validation Errors */
  @Prop() errorMessages!: string
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, required: true }) environmentVariables!: Record<
    string,
    any
  >

  set syncValue(value: string | string[]) {
    this.$emit('input', value)
  }

  get syncValue() {
    return this.value
  }
}
