
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import CaptureWizard from './CaptureWizard.vue'
import DigitalSignatureWizard from './DigitalSignatureWizard.vue'
import pdf, { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/webpack'
import axios from 'axios'
import _deburr from 'lodash/deburr'
import gql from 'graphql-tag'
import { FileFragment } from '@/components/fields/file/fragments'
import { DocumentFieldOptions } from '.'

interface PreviewPosition {
  x: number
  y: number
}

@Component({
  components: {
    Loading,
    CaptureWizard,
    DigitalSignatureWizard
  }
})
export default class DocumentEditor extends Vue {
  @Prop({ type: Boolean }) open!: boolean
  @Prop({ type: String }) pdfSource!: string
  @Prop({ type: String }) fieldName!: string
  @Prop({ type: Object, required: true }) environmentVariables!: Record<
    string,
    any
  >
  @Prop({ type: Boolean }) digital!: boolean
  @Prop({ type: Object, default: () => ({}) })
  fieldOptions!: DocumentFieldOptions

  loading = false
  saving = false
  dirty = false
  externalLoading = false
  externalProgress = ''
  externalProgressNumeric = 0
  currentSource = ''
  loadingThumbnails = false
  error = false
  errorMessage = ''
  mode = this.digital ? 'digital' : 'both'
  preview: PreviewPosition | null = null
  initialized = false

  captureWizardOpen = false
  digiSignWizardOpen = false

  pdfBytes: Uint8Array | null = null
  pdfFilename = ''
  pdfDoc: PDFDocumentProxy | null = null

  currentPageWidth = 0
  currentPageHeight = 0

  currentPageNumber = 0
  currentPage: PDFPageProxy | null = null

  thumbnails: string[] = []

  get modes() {
    const m: Record<string, any> = {
      sign: { label: this.$t('document_editor.script.modes.sign'), icon: 'edit' },
      fingerprint: { label: this.$t('document_editor.script.modes.finger'), icon: 'fingerprint' },
      both: { label: this.$t('document_editor.script.modes.both'), icon: 'gradient' }
    }
    if (this.digital) {
      m.digital = { label: this.$t('document_editor.script.modes.digital'), icon: 'vpn_key' }
    }
    return m
  }

  get docName() {
    if (this.pdfFilename) return this.pdfFilename
    if (this.pdfSource) {
      const uri = new URL(this.pdfSource)
      return uri.pathname.split('/').reverse()[0]
    }
    return ''
  }

  get isOpen() {
    return this.open
  }

  set isOpen(v: boolean) {
    this.$emit('update:open', v)
  }

  close() {
    this.isOpen = false
  }

  /**
   * Show error message.
   * @param message - Error message.
   */
  showErrror(message: string) {
    this.error = true
    this.errorMessage = message
  }

  /**
   * Close error message.
   */
  dismissError() {
    this.error = false
    this.errorMessage = ''
  }

  /**
   * Loads a PDF file from a byte array.
   * @param pdfBytes - The PDF to load.
   */
  async loadPdf(pdfBytes: Uint8Array) {
    this.loading = true
    try {
      // Attempt to load PDF document.
      this.pdfBytes = pdfBytes
      this.$set(this, 'pdfDoc', await pdf.getDocument(pdfBytes).promise)
    } catch (e) {
      console.error(e)
      this.showErrror(String(this.$t('document_editor.script.loadPdf.error')))
    } finally {
      this.loading = false
    }
  }

  async updatePdf(pdfBytes: Uint8Array) {
    this.dirty = true
    await this.loadPdf(pdfBytes)
  }

  async save() {
    if (!this.dirty) return this.close()
    if (!this.pdfBytes || this.saving) return
    this.saving = true
    try {
      this.externalProgress = ''
      const blob = new Blob([this.pdfBytes], { type: 'application/pdf' })
      // Get upload credentials
      const { data: credentials } = await this.$apollo.mutate({
        mutation: gql`
          mutation generateUploadCredentials(
            $name: String
            $size: Float
            $type: String
          ) {
            result: generateUploadCredentials(
              name: $name
              size: $size
              type: $type
            ) {
              fileId
              url
              fields
              key
            }
          }
        `,
        variables: {
          name: _deburr(this.docName.toLowerCase().replace(/[^\w\d]/g, '')),
          size: blob.size,
          type: blob.type
        }
      })
      if (!credentials.result) throw new Error(String(this.$t('document_editor.script.save.error')))
      const destination = credentials.result
      // Upload File
      const { data: upload } = await axios({
        url: destination.url,
        method: 'post',
        transformRequest: [
          (data) => {
            const formData = new FormData()
            for (const key in data) {
              if (!data.hasOwnProperty(key)) continue
              if (key === 'file') {
                formData.append(key, data[key], this.docName)
              } else {
                formData.append(key, data[key])
              }
            }
            return formData
          }
        ],
        data: {
          ...destination.fields,
          key: destination.key,
          file: blob
        },
        onUploadProgress: (progress) => {
          this.externalProgressNumeric =
            (progress.loaded / progress.total) * 100
          this.externalProgress = `${Math.round(this.externalProgressNumeric)}%`
        }
      })
      // Complete Upload
      this.externalProgress = 'Procesando...'
      this.externalProgressNumeric = 0
      const { data: result } = await this.$apollo.mutate({
        mutation: gql`
          mutation completeUpload($fileId: ID) {
            uploadedFile: completeUpload(fileId: $fileId) {
              ...FileManagerFile
            }
          }
          ${FileFragment}
        `,
        variables: {
          fileId: destination.fileId
        }
      })
      this.dirty = false
      this.$emit('file', result.uploadedFile)
      this.close()
    } catch (e) {
      console.error(e)
      this.externalProgress = ''
      this.externalProgressNumeric = 0
      this.close()
    } finally {
      this.saving = false
    }
  }

  /**
   * Loads a PDF file from an url
   * @param pdfUrl - The URL to load.
   */
  async loadPdfFromURL(pdfUrl: string) {
    this.loading = true
    this.externalLoading = true
    try {
      const { data } = await axios.get(pdfUrl, {
        responseType: 'arraybuffer',
        onDownloadProgress: (progress) => {
          this.externalProgressNumeric =
            (progress.loaded / progress.total) * 100
          this.externalProgress = `${Math.floor(this.externalProgressNumeric)}%`
        }
      })
      this.currentSource = pdfUrl
      await this.loadPdf(new Uint8Array(data))
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
      this.externalLoading = false
      this.externalProgress = ''
      this.externalProgressNumeric = 0
    }
  }

  openFileDialog() {
    const fileInput = this.$refs.file as HTMLInputElement
    fileInput.click()
  }

  /**
   * Download current PDF File
   */
  downloadPdf() {
    if (!this.pdfBytes) return
    const link = document.createElement('a')
    const blob = new Blob([this.pdfBytes], { type: 'application/pdf' })
    link.href = URL.createObjectURL(blob)
    link.download = this.docName || 'Documento.pdf'
    link.click()
  }

  async processFileInput() {
    const fileInput = this.$refs.file as HTMLInputElement
    console.log('Got file event', fileInput.files)
    const reader = new FileReader()
    console.log('Reader created')
    if (reader && fileInput.files && fileInput.files.length) {
      console.log('File: ', fileInput.files[0])
      reader.onload = async () => {
        console.log('File read.')
        const bytes = reader.result as ArrayBuffer
        console.log('Loading PDF...')
        await this.loadPdf(new Uint8Array(bytes))
        console.log('PDF Loaded')
        this.currentPageNumber = 1
        this.dirty = true
      }
      console.log('Starting read process...')
      reader.readAsArrayBuffer(fileInput.files[0])
      this.pdfFilename = fileInput.files[0].name
    }
  }

  async renderPage() {
    if (!this.currentPage) return
    const viewport = this.currentPage.getViewport({ scale: 1 })
    this.currentPageWidth = viewport.width
    this.currentPageHeight = viewport.height

    const canvas = this.$refs.pageCanvas as HTMLCanvasElement
    await this.currentPage.render({
      canvasContext: canvas.getContext('2d')!,
      viewport
    }).promise
  }

  async generateThumbnails() {
    if (!this.pdfDoc) return
    this.loadingThumbnails = true
    this.thumbnails = await Promise.all(
      new Array(this.pdfDoc.numPages).fill(null).map(async (p, i) => {
        const page = await this.pdfDoc!.getPage(i + 1)
        if (!page) return ''

        const canvas = document.createElement('canvas')
        canvas.width = canvas.height = 96

        const viewport = page.getViewport({ scale: 1 })
        const scale = Math.min(
          canvas.width / viewport.width,
          canvas.height / viewport.height
        )
        const scaledViewport = page.getViewport({ scale })
        await page.render({
          canvasContext: canvas.getContext('2d')!,
          viewport: scaledViewport
        }).promise
        return canvas.toDataURL()
      })
    )
    this.loadingThumbnails = false
  }

  updatePreviewPosition(e: MouseEvent) {
    const previewHeight = 96
    const previewWidth = 240
    this.preview = {
      x: e.offsetX - previewWidth / 2,
      y: e.offsetY - previewHeight / 2
    }
  }

  resetPreview() {
    if (this.captureWizardOpen || this.digiSignWizardOpen) return
    this.preview = null
  }

  insert(e: MouseEvent) {
    if (this.mode === 'digital') {
      this.digiSignWizardOpen = true
    } else {
      this.captureWizardOpen = true
    }
  }

  onCaptureWizardClose() {
    this.resetPreview()
  }

  nextPage() {
    if (this.pdfDoc && this.currentPageNumber + 1 < this.pdfDoc.numPages) {
      this.currentPageNumber += 1
    }
  }

  prevPage() {
    if (this.pdfDoc && this.currentPageNumber - 1 > 0) {
      this.currentPageNumber += 1
    }
  }

  /**
   * Handle page change. Render the new page.
   */
  @Watch('currentPageNumber')
  async onPageChanged() {
    if (!this.pdfDoc) return
    this.loading = true
    try {
      this.$set(
        this,
        'currentPage',
        await this.pdfDoc.getPage(this.currentPageNumber)
      )
      await this.renderPage()
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }

  /**
   * Handle Opening.
   */
  @Watch('open')
  async onOpen(v: boolean) {
    if ((!v && !this.pdfSource) || this.dirty) {
      this.initialized = false
      this.dirty = false
      this.currentSource = ''
      await this.onSourceChanged('')
      return
    }
    if (this.initialized || !v) return
    this.initialized = true
    await this.onSourceChanged(this.pdfSource)
  }

  @Watch('pdfSource')
  async onSourceChanged(newSrc: string) {
    if (!this.open) {
      this.initialized = false
      return
    }
    if (newSrc && newSrc !== this.currentSource) {
      this.pdfDoc = null
      this.pdfBytes = null
      this.currentPage = null
      await this.loadPdfFromURL(newSrc)
      this.currentPageNumber = 1
    } else if (!newSrc) {
      this.pdfDoc = null
      this.pdfBytes = null
      this.currentSource = ''
    }
  }

  /**
   * Handle document change
   * @param doc - New document
   */
  @Watch('pdfDoc')
  async onDocumentChanged(doc: PDFDocumentProxy | null) {
    if (doc) {
      await this.generateThumbnails()
      await this.onPageChanged()
    } else {
      this.currentPage = null
      this.thumbnails = []
      this.currentPageWidth = 0
      this.currentPageHeight = 0
    }
  }
}
