
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Table } from '@/models'
import { TableCreateFragment, TableEditFragment } from './fragments'
import Loading from '@/components/Loading.vue'
import TableFields from '@/components/fields/tableFields/Field.vue'
import TableFooter from '@/components/fields/tableFooter/Field.vue'
import Fields from '@/components/form/Fields.vue'
import ComponentSelect from '@/components/fields/componentSelect/Field.vue'

import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import gql from 'graphql-tag'
import cleanData from '@/utils/gql/cleanData'
import { confirmDelete } from '@/components/dialogs'

@Component({
  components: {
    Loading,
    TableFields,
    TableFooter,
    Fields,
    ComponentSelect
  },
  apollo: {
    savedTable: {
      query: gql`
        query savedTable($tableId: ID) {
          savedTable: table(tableId: $tableId) {
            ...TableCreate
            ...TableEdit
            collection {
              fields {
                name
                label
                type
                options
              }
            }
          }
        }
        ${TableCreateFragment}
        ${TableEditFragment}
      `,
      variables() {
        return {
          tableId: this.componentId
        }
      }
    }
  }
})
export default class TableEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String, required: true }) componentId!: String
  @Prop({ type: String, default: 'component' }) currentTab!: String

  mounted() {
    this.$emit('additionalTabs', {
      columns: {
        label: this.$t('tables_edit.script.mounted.columns'),
        icon: 'view_column'
      },
      footer: {
        label: this.$t('tables_edit.script.mounted.footer'),
        icon: 'border_bottom'
      }
    })
  }

  saving = false
  valid = false

  table: Partial<Table> = {}
  savedTable: Table | null = null

  @Watch('savedTable')
  update(newData: Table) {
    this.$set(this, 'table', _cloneDeep(this.savedTable))
  }

  @Watch('table.name')
  updateName(newName: string) {
    this.$emit('name', newName)
  }

  get primaryColor() {
    return (this.$vuetify.theme.currentTheme.primary as any).base
  }

  get bindedStyles() {
    const content: any = {
      margin: 'auto',
      maxWidth: '100%'
    }

    if (this.$vuetify.breakpoint.mdAndUp) content.width = '588px'

    return {
      mainContainer: {
        backgroundColor: this.$vuetify.theme.currentTheme['background-2'],
        overflowY: 'auto'
      },
      content
    }
  }

  normalize() {
    if (!this.table.collectionId) this.table.filtersIds = []
    if (!this.table.filtersIds) {
      this.table.allowsNoFilter = true
      this.table.filterByDefault = ''
    }
    if (
      !this.table.allowsNoFilter &&
      this.table.filtersIds &&
      this.table.filtersIds.length <= 1
    ) {
      this.table.filterByDefault = this.table.filtersIds[0]
    }
  }

  async save() {
    if (!this.table || this.saving) return
    this.saving = true
    try {
      this.normalize()
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($tableId: ID, $table: UpdateTableInput) {
            updateTable(tableId: $tableId, table: $table) {
              ...TableCreate
              ...TableEdit
              collection {
                fields {
                  name
                  label
                  type
                  options
                }
              }
            }
          }
          ${TableCreateFragment}
          ${TableEditFragment}
        `,
        // Parameters
        variables: {
          tableId: this.table._id,
          table: cleanData(this.table, TableEditFragment)
        }
      })

      this.savedTable = result.data.updateTable
      this.$emit('save', result.data.updateTable)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        this.$t('tables_edit.script.deleteItem.confirm').toString(),
      ))
    )
      return
    if (!this.table || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($tableId: ID) {
            removeTable(tableId: $tableId)
          }
        `,
        // Parameters
        variables: {
          tableId: this.table._id
        }
      })
      this.$emit('delete', result.data.deleteTable)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  get dirty() {
    return !_isEqual(this.table, this.savedTable)
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
