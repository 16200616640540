var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(
        _vm.isAdmin ||
        _vm.environmentUser.canAddUsers ||
        _vm.environmentUser.canRemoveUsers ||
        _vm.environmentUser.canEditProfiles ||
        _vm.environmentUser.canManageRoles
      )?_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('Manage_home.title')))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t('Manage_home.subtitle')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","to":"/manage/users"}},[_vm._v(" "+_vm._s(_vm.$t('Manage_home.listBtn'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("arrow_forward")])],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }