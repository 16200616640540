
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import {
  Task,
  TaskLogEntry,
  TaskStatus,
  TaskStatusEntry,
  Environment,
  EnvVariable
} from '@/models'
import { EnvironmentVariableUpdateFragment } from '@/views/Admin/Settings/fragments'
import { TaskFragment, TaskUpdateFragment } from '../fragments'
import Fields from '@/components/form/Fields.vue'
import PreviewParams from '@/components/tools/PreviewParams.vue'
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'
import gql from 'graphql-tag'
import cleanData from '@/utils/gql/cleanData'
import { alert, confirmDelete, prompt } from '@/components/dialogs'
import ComponentSelect from '@/components/fields/componentSelect/Field.vue'
import CollectionFieldSelect from '@/components/fields/collectionFieldSelect/Field.vue'
import SingleSelect from '@/components/fields/select/Field.vue'
import ContentField from '@/components/fields/content/Field.vue'
import ComponentEditorDialog from '@/components/ComponentEditorDialog.vue'
import * as monaco from 'monaco-editor'
import MonacoEditor, { editorEnv } from '@/plugins/monaco'
import OptionFields from '@/components/form/OptionFields.vue'
import { ApolloQueryResult } from 'node_modules/apollo-client'
import moment from '@/plugins/moment'

@Component({
  components: {
    Loading,
    Fields,
    PreviewParams,
    ComponentSelect,
    CollectionFieldSelect,
    SingleSelect,
    MonacoEditor,
    ComponentEditorDialog,
    ContentField,
    OptionFields
  },
  apollo: {
    savedTask: {
      query: gql`
        query getTask($taskId: ID) {
          savedTask: task(taskId: $taskId) {
            ...Task
          }
        }
        ${TaskFragment}
      `,
      variables() {
        return {
          taskId: this.componentId
        }
      }
    },

    savedEnvironment: {
      query: gql`
        query getEnvironment($environmentId: ID) {
          savedEnvironment: environment(environmentId: $environmentId) {
            _id
            ...EnvironmentVariableUpdateFragment
          }
        }
        ${EnvironmentVariableUpdateFragment}
      `,
      variables() {
        return {
          environmentId: this.environmentId
        }
      },
      async result({ data }: any) {
        const { savedEnvironment } = data
        if (savedEnvironment) {
          this.environment = savedEnvironment
          await this.$apollo.queries.environment.refetch()
        }
      }
    },

    $subscribe: {
      taskLogInserted: {
        query: gql`
          subscription taskLogInserted($taskId: ID) {
            taskLogInserted(taskId: $taskId) {
              t
              m
            }
          }
        `,
        variables() {
          return {
            taskId: this.task._id
          }
        },
        result({ data }: any) {
          const { taskLogInserted } = data
          this.savedTask.lastExecutionLog.push(taskLogInserted)
          this.task.lastExecutionLog.push(taskLogInserted)
        }
      },
      taskStatusChanged: {
        query: gql`
          subscription taskStatusChanged($taskId: ID) {
            taskStatusChanged(taskId: $taskId) {
              status
            }
          }
        `,
        variables() {
          return {
            taskId: this.task._id
          }
        },
        async result({ data }: any) {
          const { taskStatusChanged } = data
          // this.savedTask.status = taskStatusChanged.status
          // this.task.status = taskStatusChanged.status
          await this.$apollo.queries.savedTask.refetch()
          this.$emit('updated')
        }
      }
    }
  }
})
export default class TaskEditorEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) componentId!: string
  activeTab = null

  savedEnvironment: Partial<Environment> | null = null
  environment: Partial<Environment> | null = null

  saving = false
  running = false
  stopping = false
  previewParams: any[] = []
  previewError = ''

  savedTask: Readonly<Task> | null = null
  task: Partial<Task> = {}

  taskExecute = {
    select: null,
    value: null
  }

  inlineEditComponentType = ''
  inlineEditComponentId = ''
  inlineEditComponentOpen = false
  availableConditionTypes = [
    {
      label: this.$t('tasks_edit.script.availableCondt.cron'),
      value: 'cron',
      icon: 'event'
    },
    {
      label: this.$t('tasks_edit.script.availableCondt.rate'),
      value: 'rate',
      icon: 'schedule'
    }
  ]
  runningTimer = '00:00:00'
  runningTimerInterval = 0

  get taskExecuteValue() {
    const { environment, task } = this
    const customVariables = environment?.customVariables || []

    const variable = customVariables.find((v) => v.name === task.envName)
    return variable ? variable.value === task.envValue : false
  }

  get currentStatus() {
    return (
      (this.task.statusLog && this.task.statusLog.slice(-1)[0]) || {
        timestamp: this.task.createdAt,
        status: 'stopped',
        reason: this.$t('tasks_edit.script.currentStatus.reason')
      }
    )
  }

  get currentStatusTimestamp() {
    return moment(this.currentStatus.timestamp).format('YYYY-MM-DD@HH:mm:ss')
  }

  get codeEditorOptions() {
    return {
      automaticLayout: true,
      readonly: this.readOnly
    }
  }

  get lastExecutionLog() {
    if (!this.task || !this.task.lastExecutionLog) return ''
    return [...(this.task.lastExecutionLog || [])]
      .sort((a, b) => new Date(a.t).getTime() - new Date(b.t).getTime())
      .map((l) => l.m)
      .join('')
  }

  get statusLog() {
    if (!this.task || !this.task.statusLog) return ''
    return [...(this.task.statusLog || [])]
      .sort(
        (a, b) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      )
      .map((e) => ({
        ...e,
        timestamp: moment(e.timestamp).format('YYYY-MM-DD@HH:mm:ss')
      }))
  }

  get conditions() {
    return this.task.execConditions || []
  }

  get lastRunText() {
    if (!this.task) return ''
    if (!this.task.lastExecutionTime)
      return this.$t('tasks_edit.script.lastRunText.return')
    const lastExec = moment(this.task.lastExecutionTime)
    return `${lastExec.format('YYYY-MM-DD@HH:mm:ss')} (${lastExec.fromNow()})`
  }

  get dirty() {
    return !_isEqual(this.task, this.savedTask)
  }

  get readOnly() {
    return (
      !this.task ||
      this.$apollo.loading ||
      this.saving ||
      this.running ||
      this.task.ephemeral ||
      this.task.status === TaskStatus.Running
    )
  }

  get executionTimeout() {
    return moment
      .duration(this.task.executionTimeout || 300, 'seconds')
      .toISOString()
      .replace('P', '')
      .replace('T', '')
  }

  get maxMemoryMB() {
    return this.task.maxMemoryMB || 256
  }

  mounted() {
    this.runningTimerInterval = setInterval(
      () => this.updateTimer(),
      1000
    ) as unknown as number
  }

  beforeDestroy() {
    clearInterval(this.runningTimerInterval)
  }

  @Watch('savedTask')
  update(savedTask: Task) {
    const newTask = _cloneDeep(this.savedTask)
    this.$set(this, 'task', newTask)
  }

  @Watch('task.name')
  updateName(newName: string) {
    this.$emit('name', newName)
  }

  updateTimer() {
    if (this.task.status !== TaskStatus.Running) return
    this.runningTimer = moment
      .utc(moment().diff(this.currentStatus.timestamp))
      .format('HH:mm:ss')
  }

  addCondition(type: string) {
    if (!this.task.execConditions) this.task.execConditions = []
    let name =
      this.$t('tasks_edit.script.addCondition.name') +
      (this.conditions.length + 1)
    let options = {}
    if (type === 'cron') {
      name =
        this.$t('tasks_edit.script.addCondition.cronName') +
        (this.conditions.filter((c: any) => c.type === 'cron').length + 1)
      options = { time: '06:00' }
    }
    if (type === 'rate') {
      name =
        this.$t('tasks_edit.script.addCondition.rateName') +
        (this.conditions.filter((c: any) => c.type === 'rate').length + 1)
      options = { rate: 5, period: 'minutes' }
    }
    this.task.execConditions.push({ type, name, options, created: Date.now() })
  }

  removeCondition(index: string) {
    if (!this.task.execConditions) this.task.execConditions = []
    this.task.execConditions.splice(index, 1)
  }

  getIconForCondition(condition: any) {
    if (!condition) return
    return (
      this.availableConditionTypes.find((t) => t.value === condition) || {}
    ).icon
  }

  isChildTaskDeleted(statusEntry: TaskStatusEntry) {
    return moment(statusEntry.timestamp).add(15, 'minutes').isBefore(moment())
  }

  async run() {
    if (!this.task || this.dirty || this.readOnly || this.taskExecuteValue)
      return
    this.running = true
    try {
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($taskId: ID) {
            runTask(taskId: $taskId)
          }
        `,
        // Parameters
        variables: {
          taskId: this.task._id
        }
      })
      await this.$apollo.queries.savedTask.refetch()
      this.$emit('updated')
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.running = false
    }
  }

  async stop() {
    if (!this.task) return
    this.stopping = true
    try {
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($taskId: ID) {
            stopTask(taskId: $taskId)
          }
        `,
        // Parameters
        variables: {
          taskId: this.task._id
        }
      })
      await this.$apollo.queries.savedTask.refetch()
      this.$emit('updated')
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.stopping = false
    }
  }

  async save() {
    if (!this.dirty || this.readOnly) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($taskId: ID, $task: UpdateTaskInput) {
            updateTask(taskId: $taskId, task: $task) {
              ...Task
            }
          }
          ${TaskFragment}
        `,
        // Parameters
        variables: {
          taskId: this.task._id,
          task: {
            ...cleanData(this.task, TaskUpdateFragment),
            execTask: this.taskExecuteValue
          }
        }
      })

      this.savedTask = result.data.updateTask
      this.$emit('updated')
      this.$emit('save', result.data.updateTask)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        this.$t('tasks_edit.script.deleteItem.confirm').toString()
      ))
    )
      return
    if (!this.task || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($taskId: ID) {
            deleteTask(taskId: $taskId)
          }
        `,
        // Parameters
        variables: {
          taskId: this.task._id
        }
      })
      this.$emit('updated')
      this.$emit('delete', result.data.deleteTask)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  async rename() {
    const newName = await prompt(
      this.$t('tasks_edit.script.rename.prompt').toString(),
      {
        defaultValue: this.task.name
      }
    )
    if (newName !== false) {
      this.task.name = newName as string
      await this.save()
    }
  }

  async duplicate() {
    const cloneName = await prompt(
      this.$t('tasks_edit.script.duplicate.prompt').toString(),
      {
        defaultValue:
          this.task.name +
          this.$t('tasks_edit.script.duplicate.copy').toString()
      }
    )
    if (cloneName !== false) {
      try {
        this.saving = true
        const { data } = await this.$apollo.mutate({
          mutation: gql`
            mutation duplicateTask($taskId: ID, $newName: String) {
              result: duplicateTask(taskId: $taskId, newName: $newName) {
                _id
              }
            }
          `,
          variables: {
            taskId: this.componentId,
            newName: cloneName
          }
        })
        const { _id: newId } = data.result
        this.$emit('updated')
        return this.$router.push({
          name: 'adminTaskEdit',
          params: {
            environmentId: this.environmentId,
            componentId: newId
          }
        })
      } catch (e) {
        console.error(e)
        this.$emit('error', e)
      } finally {
        this.saving = false
      }
    }
  }

  editorDidMount(editor: monaco.editor.IStandaloneCodeEditor) {
    editorEnv.environmentId = this.environmentId
    // Options
    const model = editor.getModel()
    model?.updateOptions({
      tabSize: 2,
      insertSpaces: true
    })

    // Actions
    editor.addAction({
      id: 'save',
      label: this.$t('tasks_edit.script.addAction.label').toString(),
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S],
      run: () => this.save()
    })
    // Events
    editorEnv.onEditComponent = (componentType, componentId) => {
      this.inlineEditComponentOpen = true
      this.inlineEditComponentType = componentType
      this.inlineEditComponentId = componentId
    }
  }
}
