
import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface HyperlinkFieldOptions {
  icon?: string
  placeholder?: string
  norules?: boolean
  rules?: Record<string, any>
  noicon?: boolean
}

@Component({})
export default class HyperlinkField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Rules prop */
  @Prop({type: Array, required: false}) rules!:Record<string, any>[]
  /** Rules disabled */
  @Prop({type: Boolean, default: false}) norules!: boolean
  /** Icon disabled */
  @Prop({type: Boolean, default: true}) noicon!: boolean
  /** outlined prop */
  @Prop({type: Boolean, default: true}) outlined!: boolean
  /** dense prop */
  @Prop({type: Boolean, default: true}) dense!: boolean

  get getIcon(){
    if(this.noicon || this.fieldOptions.noicon) return ''
    return this.fieldOptions.icon || 'link'
  }

  /** Validation Rules */
  get validationRules() {

    if(this.norules || this.fieldOptions.norules) return []

    const rules = this.rules || this.fieldOptions.rules || []
    
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || this.$t('hyperlink_field.script.validationRules.required'))
    }
    // URL validation
    rules.push((v: string) => {
      if (!v) return true
      try {
        new URL(v)
      } catch (e) {
        return this.$t('hyperlink_field.script.validationRules.invalid')
      }
    })
    return rules
  }

  /** Additional field options */
  get fieldOptions(): HyperlinkFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Input event handler */
  handleInput(...args: any[]) {
    this.$emit('input', ...args)
  }
}
