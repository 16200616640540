var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BlockView',{attrs:{"environmentId":_vm.table.environmentId,"componentId":_vm.blockId,"itemDefinition":{
    id: _vm.blockId,
    type: 'block',
    sizeSmall: 12,
    sizeLarge: 12,
    blockId: _vm.blockId,
    namespace: 'blocks'
  },"preview":_vm.preview,"viewParams":_vm.itemParams}})
}
var staticRenderFns = []

export { render, staticRenderFns }