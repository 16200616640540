
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import CollectionEdit from '@/components/componentTypes/collections/Edit.vue'

interface AdditionalTab {
  name: string
  icon: string
}

@Component({
  components: {
    Loading,
    CollectionEdit
  },
  apollo: {}
})
export default class AdminCollectionEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) collectionId!: string

  displayName = ''
  currentTab = 'component'

  additionalTabs: Record<string, AdditionalTab> = {}

  get hasAdditionalTabs() {
    return Object.keys(this.additionalTabs).length
  }

  updateAdditionalTabs(additionalTabs: Record<string, string>) {
    this.$set(this, 'additionalTabs', additionalTabs)
  }

  updateName(name: string) {
    this.displayName = name
  }

  async handleDelete() {
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: this.$t('admin_collec_edit.script.delete').toString()
    })
    this.$emit('refreshCollections')
    return this.$router.replace({ name: 'adminCollectionIndex' })
  }

  async handleSave() {
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: this.$t('admin_collec_edit.script.update').toString()
    })
  }

  async handleError(e: any) {
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: 'Error: ' + e.message,
      color: 'error'
    })
  }
}
