
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Terminal as XTerminal } from '@/plugins/xterm'
import { WebLinksAddon } from 'xterm-addon-web-links'
import { FitAddon } from 'xterm-addon-fit'

@Component({
  name: 'Terminal',
  components: {}
})
export default class Terminal extends Vue {
  @Prop({ type: Object, default: () => {} }) options!: any

  terminal: XTerminal | null = null
  fitAddon: FitAddon | null = null

  mounted() {
    this.terminal = new XTerminal({
      fontFamily: 'monospace',
      fontSize: 14,
      convertEol: true,
      cols: 120,
      ...this.options
    })
    this.terminal.loadAddon(new WebLinksAddon())
    this.fitAddon = new FitAddon()
    this.terminal.loadAddon(this.fitAddon)
    this.terminal.open(this.$el as HTMLElement)
    this.fitAddon.fit()
  }

  beforeDestroy() {
    if (this.terminal) {
      this.terminal.dispose()
    }
  }
}
