var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"main-text mt-4",staticStyle:{"max-width":"960px"}},[_c('h1',{staticClass:"text-h4",domProps:{"textContent":_vm._s(_vm.block.name)}}),_c('h2',{staticClass:"mt-4 overline"},[_vm._v(_vm._s(_vm.$t('fullview_editOpt.basicOpt')))]),_c('Fields',{attrs:{"environment-variables":{ environmentId: _vm.environmentId },"schema":{
      title: {
        type: 'string',
        label: _vm.$t('fullview_editOpt.schema.title.label'),
        description: _vm.$t('fullview_editOpt.schema.title.description'),
        optional: true,
        sizeSmall: '12',
        fieldOptions: {
          color: 'green'
        },
        newDesign: true
      },
      roles: {
        type: 'roleSelect',
        __graphQLType: '[ID]',
        label: _vm.$t('fullview_editOpt.schema.roles.label'),
        fieldOptions: {
          multi: true
        },
        optional: true,
        newDesign: true
      }
    }},model:{value:(_vm.block),callback:function ($$v) {_vm.block=$$v},expression:"block"}}),_c('h2',{staticClass:"mt-4 overline"},[_vm._v(_vm._s(_vm.$t('fullview_editOpt.autoActu')))]),_c('Fields',{attrs:{"schema":{
      autoUpdateData: {
        type: 'boolean',
        label: _vm.$t('fullview_editOpt.schema.autoUpdate.label'),
        optional: true,
        sizeSmall: '12',
        fieldType: 'checkbox',
        fieldOptions: {
          color: 'green'
        },
        newDesign: true
      }
    }},model:{value:(_vm.block),callback:function ($$v) {_vm.block=$$v},expression:"block"}}),(_vm.block.autoUpdateData)?_c('Fields',{staticStyle:{"margin-top":"-24px"},attrs:{"schema":{
      injectNewData: {
        type: 'boolean',
        label: _vm.$t('fullview_editOpt.schema.inject.label'),
        optional: true,
        sizeSmall: '12',
        fieldType: 'checkbox',
        fieldOptions: {
          color: 'green'
        },
        newDesign: true
      }
    }},model:{value:(_vm.block),callback:function ($$v) {_vm.block=$$v},expression:"block"}}):_vm._e(),_c('h2',{staticClass:"mt-4 overline"},[_vm._v(_vm._s(_vm.$t('fullview_editOpt.actions')))]),_c('v-container',{staticClass:"mb-8",attrs:{"fluid":""}},[_c('v-btn',{staticClass:"mx-1 action-btn",attrs:{"outlined":"","block":""},on:{"click":_vm.rename}},[_c('v-list-item',[_c('v-icon',{staticClass:"mx-4",attrs:{"size":"48"}},[_vm._v("text_format")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('fullview_editOpt.renameBlock')))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('fullview_editOpt.renameIdent')))])],1)],1)],1),_c('v-btn',{staticClass:"mt-4 mx-1 action-btn",attrs:{"outlined":"","block":""},on:{"click":_vm.duplicate}},[_c('v-list-item',[_c('v-icon',{staticClass:"mx-4",attrs:{"size":"48"}},[_vm._v("content_copy")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('fullview_editOpt.duplicate')))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('fullview_editOpt.duplicateDescp')))])],1)],1)],1),_c('v-btn',{staticClass:"mt-4 mx-1 action-btn",attrs:{"color":"warning","outlined":"","block":""},on:{"click":_vm.deleteItem}},[_c('v-list-item',[_c('v-icon',{staticClass:"mx-4",attrs:{"size":"48","color":"warning"}},[_vm._v("delete")]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"warning--text"},[_vm._v(_vm._s(_vm.$t('fullview_editOpt.delete')))]),_c('v-list-item-subtitle',{staticClass:"warning--text"},[_vm._v(_vm._s(_vm.$t('fullview_editOpt.deleteDescp')))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }