
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Chart, TableChartResult } from '@/models'
import ViewItems from '@/components/componentTypes/tables/ViewItems.vue'

@Component({
  components: {
    ViewItems
  }
})
export default class TableChart extends Vue {
  @Prop({ type: Object, required: true }) chart!: Chart
  @Prop({ type: Object, required: true }) result!: TableChartResult
  @Prop({ type: Object, default: () => ({}) }) queryOptions!: Record<
    string,
    any
  >
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Boolean, default: false }) loading!: boolean

  get pagination() {
    return {
      page: this.queryOptions.page || 1,
      itemsPerPage: this.queryOptions.limit || this.result.table.defaultLimit, // this.result.table.defaultLimit || 25,
      sortBy: [this.queryOptions.sortBy || 'data'],
      sortDesc: [
        this.queryOptions.sortType != null
          ? this.queryOptions.sortType === 'DESC'
          : true
      ],
      totalItems: this.result.totalCount,
      multiSort: false
    }
  }

  set pagination(v) {
    this.$emit('update:queryOptions', {
      page: v.page,
      sortType: v.sortDesc[0] ? 'DESC' : 'ASC',
      limit: v.itemsPerPage,
      sortBy: v.sortBy[0]
    })
  }
}
