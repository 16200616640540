
import { Vue, Component, Prop, Inject, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { FormField } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import { showDialogPromise } from '@/components/dialogs'
import CollectionWatcher from '@/components/tools/CollectionWatcher.vue'
import runtimeParams from '@/utils/runtime/runtimeParams'

interface ManyOfFieldOptions {
  collectionId: string
  filterId?: string
  labelKey: string
  valueKey: string
  filterFields?: string
  createForm?: string
  typeMultiselect?: boolean
  disableRefresh?: boolean
}

interface ManyOfItem {
  label: string
  value: string
}

@Component({
  components: {
    CollectionWatcher
  },
  apollo: {
    items: {
      query: gql`
        query getFormManyOfSelectOptions(
          $environmentId: ID
          $formId: ID
          $collectionId: ID
          $tableId: ID
          $fieldName: String
          $collectionFieldName: String
          $searchQuery: String
          $limit: Float
          $filterId: ID
          $filterOptions: JSON
          $currentValueArray: [String]
        ) {
          items: selectOptions(
            environmentId: $environmentId
            formId: $formId
            collectionId: $collectionId
            tableId: $tableId
            fieldName: $fieldName
            collectionFieldName: $collectionFieldName
            searchQuery: $searchQuery
            limit: $limit
            filterId: $filterId
            filterOptions: $filterOptions
            currentValueArray: $currentValueArray
          ) {
            label
            value
          }
        }
      `,
      variables() {
        return {
          environmentId: this.environmentVariables.environmentId,
          formId: this.environmentVariables.formId,
          collectionId: this.environmentVariables.collectionId,
          tableId: this.environmentVariables.tableId,
          fieldName: this.name,
          collectionFieldName: this.name,
          searchQuery: this.searchQuery,
          limit: 200,
          filterId: this.fieldOptions.filterId,
          filterOptions: this.filterOptions,
          currentValueArray: this.value
        }
      },
      fetchPolicy: 'network-only'
    }
  }
})
export default class ManyOfField extends Vue {
  /** Current Value */
  @Prop({ type: Array, default: () => [] }) value!: string[]
  /** Validation Errors */
  @Prop() errorMessages!: string
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, required: true }) environmentVariables!: Record<
    string,
    any
  >
  /** Filter parameters */
  @Inject() fieldValues?: () => Record<string, any>

  searchQuery = ''
  items: ManyOfItem[] = []
  /** Selected items */
  selectedItems: string[] = []

  @Watch('value')
  cleanSelectedItems(value?: string[] | null) {
    if (value == null) {
      this.selectedItems = []
    }
  }

  /** Additional field options */
  get fieldOptions(): ManyOfFieldOptions {
    return this.schema.fieldOptions || this.schema.options || {}
  }

  get listItems() {
    let hash: any = {}
    return this.items.filter((item) =>
      hash[item.value] ? false : (hash[item.value] = true)
    )
  }

  backColor(value: string) {
    return this.value?.find((x) => x === value) ? true : false
  }

  async openCreateModal() {
    this.searchQuery = ''
    const field = this.$refs.field as any
    field.blur()

    const result = (await showDialogPromise({
      id: 'form-' + this.fieldOptions.createForm,
      component: () =>
        asyncImport(import('@/components/componentTypes/forms/View.vue')),
      wrapInCard: true,
      props: {
        environmentId: this.environmentVariables.environmentId,
        componentId: this.fieldOptions.createForm,
        itemDefinition: {
          id: this.fieldOptions.createForm,
          type: 'form',
          sizeSmall: '12',
          sizeLarge: '12',
          formId: this.fieldOptions.createForm,
          namespace: 'forms'
        },
        showCloseButton: true,
        viewParams: {}
      },
      dismissable: true
    })) as any

    if (result && result._id) {
      this.$emit('input', [
        ...(this.value || []),
        result[this.fieldOptions.valueKey]
      ])
      field.blur()
      await this.$apollo.queries.items.refetch()
    }
  }

  set selectedItemIds(value: string[]) {
    this.$emit('input', value)
  }

  selectItem(value: string) {
    if (this.selectedItems.find((x) => x === value) ? true : false) {
      this.selectedItems = this.selectedItems.filter((e) => e !== value)
      this.$emit('input', this.selectedItems)
    } else {
      this.selectedItems.push(value)
      this.$emit('input', this.selectedItems)
    }
  }
  /** Current Hook IDs (multi) */
  get selectedItemIds() {
    return this.value
  }

  /** Remove a component */
  remove(itemId: string) {
    this.$emit(
      'input',
      this.value.filter((i) => i !== itemId)
    )
  }

  /** Options for the filter */
  get filterOptions() {
    const fieldValues = (this.fieldValues && this.fieldValues()) || {}
    const params: Record<string, any> = {}
    const fields = this.fieldOptions.filterFields?.trim().split(',') || []
    for (const field of fields) {
      params[field] = fieldValues[field]
    }
    return params
  }

  async update() {
    if (this.fieldOptions.disableRefresh) {
      return
    }
    return this.$apollo.queries.items.refetch()
  }
}
