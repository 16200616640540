var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    !_vm.desktopApp &&
    (_vm.isAdmin ||
      _vm.environmentUser.canAddUsers ||
      _vm.environmentUser.canRemoveUsers ||
      _vm.environmentUser.canEditProfiles ||
      _vm.environmentUser.canManageRoles)
  )?_c('v-container',{attrs:{"fluid":""}},[_c('PaginatedList',{ref:"results",attrs:{"queryName":_vm.componentType.queryName,"queryFields":_vm.componentType.queryFields,"queryVariables":{
      environmentId: {
        type: 'ID',
        value: _vm.environment._id,
      },
    },"addButton":(!_vm.isAdmin && !_vm.environmentUser.canAddUsers) || !!_vm.componentType.create,"addButtonColor":_vm.componentType.color || 'primary',"searchBox":"","squareIcons":"","defaultIcon":_vm.componentType.icon || 'extension',"limit":50,"transformResult":_vm.transformResult},on:{"addButtonClick":_vm.toggleCreateModal}}),(_vm.componentType.edit && _vm.userId)?_c('ComponentEditorDialog',{attrs:{"componentTypeName":"environmentUsers","environmentId":_vm.environment._id,"environmentUser":_vm.environmentUser,"componentId":_vm.userId},model:{value:(_vm.componentEditorOpen),callback:function ($$v) {_vm.componentEditorOpen=$$v},expression:"componentEditorOpen"}}):_vm._e(),_c('ComponentCreateDialog',{attrs:{"environmentId":_vm.environment._id,"componentTypeName":"environmentUsers"},on:{"create":_vm.handleCreate},model:{value:(_vm.createModalOpen),callback:function ($$v) {_vm.createModalOpen=$$v},expression:"createModalOpen"}})],1):_c('v-container',{key:"error",attrs:{"fill-height":""}},[_c('v-layout',{attrs:{"column":"","align-center":"","justify-center":""}},[_c('div',[_c('v-icon',{attrs:{"size":"96"}},[_vm._v("error_outline")])],1),_c('div',{staticClass:"headline"},[_c('br'),_vm._v(_vm._s(_vm.$t('Manage.title')))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }