
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { User, Environment, EnvironmentUser, View, Link } from '@/models'
import openLink from '@/utils/route/openLink'
import StatusBar from '@/components/mobileApp/StatusBar.vue'
import UserMenu from '@/components/UserMenu.vue'
import NotificationsButton from '@/components/NotificationsButton.vue'
import TellusButton from '@/components/TellusButton.vue'
import gql from 'graphql-tag'
import _snakeCase from 'lodash/snakeCase'
import getEnv from '@/plugins/getEnv'

@Component({
  components: {
    StatusBar,
    UserMenu,
    NotificationsButton,
    TellusButton
  },
  filters: {
    fixLegacyIcons(input: string) {
      if (input && input.indexOf('Md') === 0) {
        return _snakeCase(input.slice(2))
      }
      return input
    }
  },
  apollo: {
    userMenu: {
      query: gql`
        query ($environmentId: ID) {
          userMenu(environmentId: $environmentId)
        }
      `,
      variables() {
        return {
          environmentId: this.environment._id
        }
      }
    }
  }
})
export default class EnvironmentViewNavbar extends Vue {
  @Prop({ type: Object }) environment?: Environment
  @Prop({ type: Object }) environmentUser?: EnvironmentUser
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Object }) view?: View
  @Prop({ type: String }) title?: string
  @Prop({ type: String }) path?: string
  @Prop({ type: Boolean, default: false }) sidebar!: boolean
  @Prop({ type: Boolean, default: false }) sidebarOpen!: boolean
  @Prop({ type: [Number, String] }) notificationCount?: number | string
  @Prop({ type: [Number, String] }) unreadMessageCount?: number | string
  @Prop({ type: [Number, String], default: 0 }) unreadMessageCountByClient?:
    | number
    | string
  @Prop({ type: Boolean, default: false }) editing!: boolean
  @Prop({ type: Boolean, default: false }) simulatedStatusBar!: boolean
  @Prop({ type: String }) theme?: string

  userMenu: Link[] = []
  desktopApp = getEnv('VUE_APP_IS_DESKTOP')

  mounted() {
    this.updateTitle()
    if (!this.theme)
      this.theme = this.$store.state.app.darkMode ? 'dark' : 'light'
  }
  async logout() {
    await this.$store.dispatch('auth/logout')
    await this.$router.push('/login')
    return
  }

  toggleDarkMode() {
    if (!this.environment?.allowDarkMode) return
    this.$store.commit('app/toggleDarkMode')
  }

  toggleEditLinks() {
    if (!this.isAdmin) return
    this.$store.commit('app/toggleEditLinks')
  }

  get statusStyle() {
    if (this.sidebarOpen || this.$store.state.app.darkMode)
      return 'lightcontent'
    return this.view && this.view.titleBackground ? 'lightcontent' : 'default'
  }

  get statusBackground() {
    if (this.sidebarOpen) return '#66000000'
    return this.view && this.view.titleBackground ? '#66000000' : '#00000000'
  }

  get simulatedStatusDark() {
    return !!(this.view && this.view.titleBackground)
  }

  get simulatedStatusBackground() {
    return this.view && this.view.titleBackground
      ? 'rgba(0, 0, 0, .4)'
      : 'rgba(0, 0, 0, 0)'
  }

  get simulatedStatusStyle() {
    if (!this.simulatedStatusBar) return {}
    return {
      'padding-top': '24px',
      'min-height': '88px'
    }
  }

  get fixed() {
    return this.view && this.view.titleBackground && window.mobileApp
  }

  get editLinks() {
    return (
      this.view &&
      this.isAdmin &&
      this.adminLink &&
      this.$store.state.app.editLinks
    )
  }

  get adminLink() {
    if (window.mobileApp || this.editing || !this.environment) return ''
    if (getEnv('VUE_APP_ADMIN_DOMAIN')) {
      return `https://${getEnv('VUE_APP_ADMIN_DOMAIN')}/${this.environment._id}`
    }
  }

  get legacyAdminLink() {
    if (window.mobileApp || this.editing || !this.environment) return ''
    if (getEnv('VUE_APP_ADMIN_DOMAIN_V1')) {
      return `https://${getEnv('VUE_APP_ADMIN_DOMAIN_V1')}/${
        this.environment._id
      }`
    }
  }

  get envProfile() {
    return this.environmentUser?.profile || {}
  }

  get user(): User {
    return this.$store.state.auth.user
  }

  get isAdmin() {
    return (
      this.user.roles.indexOf('admin') >= 0 ||
      this.user.roles.indexOf('superAdmin') >= 0
    )
  }

  get userAvatar(): string {
    return this.$store.getters['auth/userAvatarURL']
  }

  get userRoleDisplay(): string {
    if (!this.user) return ''
    if (this.user.roles.includes('superAdmin')) {
      return this.$t('View_Navbar.script.userRoleDisplay.superAdm').toString()
    }
    if (this.user.roles.includes('admin')) {
      return this.$t('View_Navbar.script.userRoleDisplay.admin').toString()
    }
    return this.$t('View_Navbar.script.userRoleDisplay.user').toString()
  }

  get backgroundStyle() {
    if (this.view && this.view.titleBackground) {
      return {
        background:
          this.view.titleBackground.indexOf('http') === 0
            ? `url(${this.view.titleBackground}) center / cover`
            : this.view.titleBackground
      }
    }
    return {}
  }

  get shadowStyle() {
    if (this.view && this.view.titleBackground) {
      return {
        'text-shadow':
          this.view.titleBackground.indexOf('http') === 0
            ? '0 0 5px black'
            : 'none'
      }
    }
    return {}
  }

  get color() {
    if (this.view && this.view.titleBackground) {
      return this.view.titleBackground.indexOf('http') === 0
        ? 'transparent'
        : this.view.titleBackground
    }
    return 'transparent'
  }

  get textColor() {
    if (this.view && this.view.titleColor) {
      return {
        color: this.view.titleColor
      }
    }
    return ''
  }

  get buttonColor() {
    if (!this.view) return 'primary'
    return this.view.titleBackground ? '' : 'primary'
  }

  get extended() {
    return (
      this.view &&
      ((this.view.titleBackground &&
        this.view.titleBackground.indexOf('http') === 0) ||
        this.view.backButton ||
        this.view.printButton)
    )
  }

  back() {
    if (!this.view || this.editing) return
    if (this.view.backLink) {
      return openLink(this.view.backLink, this.viewParams)
    } else {
      return this.$router.go(-1)
    }
  }

  print() {
    if (this.editing) return
    window.print()
  }

  @Watch('view')
  updateTitle() {
    if (!this.environment) return
    if (this.title) {
      document.title = this.title
    } else if (this.view && this.view.title) {
      document.title = `${this.view.title} | ${this.environment.name}`
    } else {
      document.title = this.environment.name
    }
  }
}
