
import { HookFunctionType } from '@/models'
import gql from 'graphql-tag'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class HookEditorCreate extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: Array }) functionTypes!: HookFunctionType[] | null

  name = ''
  functionTypeId = ''
  creating = false
  nameErrors: string[] = []

  get filteredFunctionTypes() {
    const hiddenTypes = ['script', 'generatePdfFromHTML']
    if (!this.functionTypes) return []
    return this.functionTypes
      .filter((f) => !hiddenTypes.includes(f._id))
      .map((f) => ({ value: f._id, text: f.name }))
  }

  async create() {
    this.creating = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation createHook(
            $environmentId: ID
            $name: String
            $functionTypeId: String
          ) {
            createHook(
              environmentId: $environmentId
              name: $name
              functionTypeId: $functionTypeId
            ) {
              _id
            }
          }
        `,
        variables: {
          environmentId: this.environmentId,
          name: this.name,
          functionTypeId: this.functionTypeId
        }
      })
      const { _id: hookId } = data.createHook
      this.$emit('created')
      return this.$router.push({
        name: 'adminHookEdit',
        params: {
          environmentId: this.environmentId,
          componentId: hookId
        }
      })
    } catch (e) {
      console.error(e)
      if (e.graphQLErrors) {
        e.graphQLErrors.forEach((err: any) => {
          if (err.error === 'validationError') {
            this.nameErrors = [
              this.$t('hoks_f_create.script.create.error').toString()
            ]
          }
        })
      }
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: this.$t('hoks_f_create.script.create.text')
      })
    } finally {
      this.creating = false
    }
  }
}
