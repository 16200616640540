
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ViewItem, Block } from '@/models'
import ComponentHeader from '@/components/ViewComponentHeader.vue'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import CollectionWatcher from '@/components/tools/CollectionWatcher.vue'
import { ApolloError } from 'apollo-client'
import FormView from '@/components/componentTypes/forms/View.vue'
import FilterForm from '@/components/form/FilterForm.vue'
import ViewBlock from './ViewBlock.vue'
import { BlockFragment } from './fragments'

interface FilterValue {
  filterId?: string
  filterOptions: Record<string, any>
}

@Component({
  name: 'BlockView',
  components: {
    Loading,
    ComponentHeader,
    CollectionWatcher,
    FormView,
    ViewBlock,
    FilterForm
  },
  apollo: {
    block: {
      query: gql`
        query getBlock($blockId: ID) {
          block(blockId: $blockId) {
            ...Block
            filters {
              _id
              title
              schema: serializedSchema(includeParameters: true)
              formSchema: serializedSchema(includeParameters: false)
            }
          }
        }
        ${BlockFragment}
      `,
      fetchPolicy: 'network-only',
      variables() {
        return {
          blockId: this.componentId
        }
      }
    }
  }
})
export default class BlockView extends Vue {
  @Prop({ type: String }) environmentId!: string
  @Prop({ type: String }) componentId!: string
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Boolean, default: false }) editing!: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Object, default: () => ({}) }) itemDefinition!: ViewItem
  @Prop({ type: Boolean, default: false }) showCloseButton!: boolean

  block: Block | null = null
  error: ApolloError | null = null
  exporting = false
  modalOpen = false
  isPendingDestroy = false
  filter: FilterValue | null = null

  @Watch('block')
  setDefaultFilter(block: Block) {
    if (block.filterByDefault) {
      this.filter = {
        filterId: block.filterByDefault,
        filterOptions: this.viewParams
      }
    } else if (block.allowsNoFilter) {
      this.filter = { filterId: undefined, filterOptions: this.viewParams }
    } else if (block.filters && block.filters.length >= 1) {
      this.filter = {
        filterId: block.filters[0]._id,
        filterOptions: this.viewParams
      }
    }
  }

  async exportBlock() {
    ;(this.$refs.viewBlock as ViewBlock).exportBlock()
  }

  beforeDestroy() {
    this.isPendingDestroy = true
  }
}
