var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('vl-map',{staticStyle:{"min-height":"400px"},attrs:{"load-tiles-while-animating":true,"load-tiles-while-interacting":true,"data-projection":"EPSG:4326"}},[_c('vl-view',{ref:"mapView",attrs:{"zoom":_vm.zoom,"center":_vm.center,"rotation":_vm.rotation},on:{"update:zoom":function($event){_vm.zoom=$event},"update:center":function($event){_vm.center=$event},"update:rotation":function($event){_vm.rotation=$event}}}),_c('vl-layer-tile',[_c('vl-source-osm')],1),_vm._l((_vm.layers),function(layer){return _c('vl-layer-vector',{key:layer.id},[_c('vl-source-vector',{attrs:{"features":layer.feature},on:{"update:features":function($event){return _vm.$set(layer, "feature", $event)}}}),_c('vl-style-box',[_c('vl-style-fill',{attrs:{"color":(layer.properties &&
            layer.properties.fill &&
            layer.properties.fill.color) ||
          'rgba(255,0,0,.5)'}}),_c('vl-style-stroke',{attrs:{"color":(layer.properties &&
            layer.properties.stroke &&
            layer.properties.stroke.color) ||
          'red'}}),_c('vl-style-circle',{attrs:{"radius":10}},[_c('vl-style-fill',{attrs:{"color":(layer.properties &&
              layer.properties.fill &&
              layer.properties.fill.color) ||
            'rgba(255,0,0,.5)'}}),_c('vl-style-stroke',{attrs:{"color":(layer.properties &&
              layer.properties.stroke &&
              layer.properties.stroke.color) ||
            'red'}})],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }