
import { Vue, Component, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'
import gql from 'graphql-tag'

@Component({
  apollo: {
    displayValues: {
      query: gql`
        query getManyOfValue(
          $tableId: ID
          $collectionId: ID
          $fieldName: String
          $value: [String]
        ) {
          displayValues: tableRelationLabels(
            tableId: $tableId
            collectionId: $collectionId
            fieldName: $fieldName
            value: $value
          )
        }
      `,
      variables() {
        return {
          tableId: this.tableId,
          collectionId: this.collectionId,
          fieldName: this.name,
          value: this.value || []
        }
      }
    }
  }
})
export default class ManyOfView extends Vue {
  @Prop({ type: Array, default: () => [] }) value!: string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, required: true }) environmentVariables!: Record<
    string,
    any
  >

  displayValues: string[] = []

  get displayValue(): string {    
    return this.displayValues?.join(', ') || ''
  }

  get tableId() {
    return this.environmentVariables && this.environmentVariables.tableId
  }

  get environmentId() {
    return this.environmentVariables && this.environmentVariables.environmentId
  }

  get collectionId() {
    return this.schema.options && this.schema.options.collectionId
  }

  get disableRefresh() {
    return this.schema.options && this.schema.options.disableRefresh
  }

  async update() {
    await this.$apollo.queries.displayValues.refetch()
  }
}
