
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import { Button } from '@/models'
import { ButtonFragment, ButtonUpdateFragment } from './fragments'
import Fields from '@/components/form/Fields.vue'
import { Field as CheckBox } from '@/components/fields/checkbox'
import { Field as TextInput } from '@/components/fields/string'
import KeyValueList from '@/components/tools/KeyValueList.vue'
import PreviewParams from '@/components/tools/PreviewParams.vue'
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'
import _snakeCase from 'lodash/snakeCase'
import gql from 'graphql-tag'
import cleanData from '@/utils/gql/cleanData'
import { confirmDelete } from '@/components/dialogs'

@Component({
  components: {
    Loading,
    Fields,
    PreviewParams,
    CheckBox,
    TextInput,
    KeyValueList
  },
  apollo: {
    savedButton: {
      query: gql`
        query getButton($buttonId: ID) {
          savedButton: button(buttonId: $buttonId) {
            ...Button
          }
        }
        ${ButtonFragment}
      `,
      variables() {
        return {
          buttonId: this.componentId
        }
      }
    }
  }
})
export default class ButtonEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String, required: true }) componentId!: String
  @Prop({ type: String, default: 'component' }) currentTab!: String

  mounted() {
    this.$emit('additionalTabs', {
      appearance: {
        label: this.$t('buttons_edit.script.mounted.appearance'),
        icon: 'palette'
      },
      actions: {
        label: this.$t('buttons_edit.script.mounted.actions'),
        icon: 'task'
      }
    })
  }

  saving = false
  valid = false

  savedButton: Readonly<Button> | null = null
  button?: Partial<Button> | any = {}

  @Watch('savedButton')
  update(newData: Button) {
    this.$set(this, 'button', _cloneDeep(this.savedButton))
  }

  @Watch('button.name')
  updateName(newName: string) {
    this.$emit('name', newName)
  }

  get primaryColor() {
    return (this.$vuetify.theme.currentTheme.primary as any).base
  }

  get bindedStyles() {
    const content: any = {
      margin: 'auto',
      maxWidth: '100%'
    }

    if (this.$vuetify.breakpoint.mdAndUp) content.width = '588px'

    return {
      mainContainer: {
        backgroundColor: this.$vuetify.theme.currentTheme['background-2'],
        overflowY: 'auto'
      },
      content
    }
  }

  get normalizedIcon() {
    if (!this.button) return
    let icon = this.button.icon || ''
    return icon.startsWith('Md') ? _snakeCase(icon.slice(2)) : icon
  }

  get dirty() {
    return !_isEqual(this.button, this.savedButton)
  }

  async save() {
    if (!this.button || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($buttonId: ID, $button: UpdateButtonInput) {
            updateButton(buttonId: $buttonId, button: $button) {
              ...Button
            }
          }
          ${ButtonFragment}
        `,
        // Parameters
        variables: {
          buttonId: this.button._id,
          button: cleanData(this.button, ButtonUpdateFragment)
        }
      })

      this.savedButton = result.data.updateButton
      this.$emit('save', result.data.updateButton)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        this.$t('buttons_edit.script.deleteItem.confirm') as string
      ))
    )
      return
    if (!this.button || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($buttonId: ID) {
            deleteButton(buttonId: $buttonId)
          }
        `,
        // Parameters
        variables: {
          buttonId: this.button._id
        }
      })
      this.$emit('delete', result.data.deleteButton)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
