
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Environment, EnvironmentUser } from '@/models'
    
@Component({
  components: {
    DefaultLayout: async ()=> import('./DefaultLayout.vue'),
    LightweightLayout: async ()=> import('./LightweightLayout.vue'),
  },
})
export default class IndexLayout extends Vue {
  @Prop({ type: Object, required: true }) environment!: Environment
  @Prop({ type: Object, required: true }) environmentUser!: EnvironmentUser
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<
    string,
    string
  >
  @Prop({ type: String }) viewId!: string
  @Prop({ type: String, default: '' }) path!: string
  @Prop({ type: Boolean, default: false }) sidebar!: boolean
  @Prop({ type: Boolean, default: false }) disableNavbar!: boolean
  @Prop({ type: Boolean, default: false }) hideLoading!: boolean
  @Prop({ type: Boolean, default: false }) noCustomCSS!: boolean
  @Prop({ type: Boolean, default: false }) noPadding!: boolean
  get getLayout() {
    return this.environment.allowLightweightLayout ? 'LightweightLayout' : 'DefaultLayout'    
  }
}
